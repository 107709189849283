import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Sider from 'antd/lib/layout/Sider';
import { DoubleRightOutlined } from '@ant-design/icons';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import Button from 'component-library/src/button/Button';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';
import ReleaseCount from 'components/nav/release-count/ReleaseCount';
import InnerMenu from 'features/navigation/InnerMenu';
import theme from 'component-library/src/theme/theme';
import { mixpanel } from 'tracking/tracking';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface DesktopSiderNavProps {
  currentMenuKey: string;
  openKeys?: React.Key[];
  handleMenuItemClickFn: MenuClickEventHandler;
  handleSignOut: () => void;
  setOpenKeys: (keys?: React.Key[]) => void;
}

const DesktopSiderNav: FC<React.PropsWithChildren<DesktopSiderNavProps>> = ({
  currentMenuKey,
  openKeys,
  setOpenKeys,
  handleMenuItemClickFn,
  handleSignOut,
}) => {
  const {
    value: siderCollapsedLocalStorage,
    setValue: setSiderCollapsedLocalStorage,
  } = useLocalStorage('sider-collapsed');
  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(
    siderCollapsedLocalStorage === 'true'
  );

  const navMenuHomeLogo = (
    <div className="logo">
      <Link to={'/'} data-testid="nav-logo" title="Home link">
        <span className="sr-only">Home link</span>
        <Logo color={theme.colors.secondaryColor} />
      </Link>
    </div>
  );

  return (
    <Sider
      className="desktop-nav-sider"
      width={270}
      trigger={null}
      collapsible
      collapsed={siderCollapsed}
      collapsedWidth={92}
    >
      <Space
        direction="vertical"
        size={spacing.space40}
        className="sider-nav-space-container w-100"
      >
        <div className="sider-nav-header w-100">
          {navMenuHomeLogo}
          <Button
            type="icon"
            className={siderCollapsed ? '' : 'sider-collapse-button'}
            icon={
              <DoubleRightOutlined style={{ color: theme.colors.secondary6 }} />
            }
            onClick={() => {
              setSiderCollapsed(!siderCollapsed);
              setSiderCollapsedLocalStorage(!siderCollapsed ? 'true' : 'false');
              setOpenKeys(undefined);
            }}
            trackEvent={mixpanel.track.bind(mixpanel)}
            testId="sider-collapse-button"
          />
        </div>

        <InnerMenu
          currentMenuKey={currentMenuKey}
          isCollapsed={siderCollapsed}
          openKeys={openKeys}
          onItemClick={handleMenuItemClickFn}
          onSignOut={handleSignOut}
          setOpenKeys={(keys) => setOpenKeys(keys)}
          setSiderCollapsed={(value) => setSiderCollapsed(value)}
        />

        {!siderCollapsed && <ReleaseCount />}
      </Space>
    </Sider>
  );
};

export default DesktopSiderNav;
