import { Axios, AxiosResponse } from 'axios';
import { instance } from 'helper/api/api';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { AlgoliaSecuredApiKeyResponse } from 'models/Algolia';

export class AlgoliaService {
  private musicHubApiAxiosInstance: Axios;
  private algoliaClientFactory: (appId: string, apiKey: string) => SearchClient;
  ALGOLIA_SECURED_API_STORAGE_KEY = 'algolia-secured-api-key-web';

  constructor(
    musicHubApiAxiosInstance: Axios,
    algoliaClientFactory = algoliasearch
  ) {
    this.musicHubApiAxiosInstance = musicHubApiAxiosInstance;
    this.algoliaClientFactory = algoliaClientFactory;
  }

  getAlgoliaApiKeyLocalStorage = (): string | null =>
    localStorage.getItem(this.ALGOLIA_SECURED_API_STORAGE_KEY);

  setAlgoliaApiKeyLocalStorage = (
    apiKeyResponse: AlgoliaSecuredApiKeyResponse
  ) =>
    localStorage.setItem(
      this.ALGOLIA_SECURED_API_STORAGE_KEY,
      JSON.stringify(apiKeyResponse)
    );

  fetchAlgoliaApiKey = async (): Promise<
    AxiosResponse<AlgoliaSecuredApiKeyResponse>
  > => await this.musicHubApiAxiosInstance.post(`search/apiKey`);

  getAlgoliaApiKey = async (): Promise<string> => {
    const securedApiKey = this.getAlgoliaApiKeyLocalStorage();

    if (
      !securedApiKey ||
      new Date().getTime() > JSON.parse(securedApiKey).validUntil
    ) {
      const { data } = await this.fetchAlgoliaApiKey();
      this.setAlgoliaApiKeyLocalStorage(data);
      return data.apiKey;
    }

    return JSON.parse(securedApiKey).apiKey;
  };

  deleteAlgoliaKey = () => {
    localStorage.removeItem(this.ALGOLIA_SECURED_API_STORAGE_KEY);
  };

  getAlgoliaClient = async (): Promise<SearchClient> => {
    const key = await this.getAlgoliaApiKey();
    return this.algoliaClientFactory(
      process.env.REACT_APP_ALGOLIA_APP_ID || '',
      key
    );
  };
}

export default new AlgoliaService(instance);
