import values from 'lodash/values';
import {
  Artwork,
  Copyright,
  Distribution,
  Release,
  ReleaseArtworkResponse,
  ReleaseCopyrightResponse,
  ReleaseDistributionResponse,
  ReleaseFile,
  ReleaseFileResponse,
  ReleaseList,
  ReleaseListResponse,
  ReleaseRecentList,
  ReleaseRecentListResponse,
  ReleaseRecentResponse,
  ReleaseResponse,
  ReleaseTitleResponse,
  Title,
} from 'features/release/models/Release';
import {
  Track,
  TrackParticipant,
  TrackParticipantResponse,
  TrackParticipants,
  TrackResponse,
} from 'features/track/models/Track';
import { ARTIST_ROLE, AUTHOR_ROLE } from 'helper/constants/constants';
import dayjs from 'dayjs';
import { normalizedDate } from 'helper/time/time';

const toTitle = (titleResponse?: ReleaseTitleResponse): Title | undefined => {
  if (!titleResponse) {
    return undefined;
  }
  const { language, version, remasteredYear } = titleResponse;
  const normalizedTitle = titleResponse.title || '';
  const normalizedLanguage = language || undefined;
  const normalizedVersion = version || undefined;
  const normalizedRemasteredYear = remasteredYear
    ? normalizedDate(remasteredYear.toString())
    : undefined;

  return {
    title: normalizedTitle,
    language: normalizedLanguage,
    version: normalizedVersion,
    remasteredYear: normalizedRemasteredYear,
  };
};

export const toCopyright = (
  copyrightResponse?: ReleaseCopyrightResponse
): Copyright => {
  const { name, year } = copyrightResponse || {};
  const normalizedName = name || undefined;
  const normalizedYear = year ? normalizedDate(year.toString()) : undefined;
  const copyright: Copyright = {
    name: normalizedName,
    year: normalizedYear,
  };
  return copyright;
};

export const toFile = ({
  originalFileName,
  storageFileName,
  fileSizeInBytes,
  fileHash,
  originalImgUri,
  resizedImgUri,
  type,
  fileUri,
  durationSeconds,
  uploadingUserId,
  createdAt,
}: ReleaseFileResponse): ReleaseFile | undefined => {
  if (!storageFileName || !originalFileName || !fileSizeInBytes) return;

  const normalizedFile: ReleaseFile = {
    uid: storageFileName,
    fileName: storageFileName,
    fileHash,
    name: originalFileName,
    size: fileSizeInBytes,
    url: originalImgUri,
    originalImgUri,
    resizedImgUri,
    fileUri,
    type: type,
    durationSeconds,
    uploadingUserId,
    createdAt,
  };
  return normalizedFile;
};

interface PerformerRoles {
  artists: TrackParticipants;
  authors: TrackParticipants;
  participants: TrackParticipants;
}

const sortByPosition = (a: TrackParticipant, b: TrackParticipant): number =>
  (a.position || -1) - (b.position || -1);

export const toPerformerRoles = (
  participantsResponse: TrackParticipantResponse[]
): PerformerRoles => {
  const AUTHOR_ROLES = values(AUTHOR_ROLE);
  const ARTIST_ROLES = values(ARTIST_ROLE);

  const result = participantsResponse.sort(sortByPosition);

  const performers = result.reduce(
    (acc: PerformerRoles, curr: TrackParticipantResponse) => {
      if (ARTIST_ROLES.includes(curr.role as ARTIST_ROLE)) {
        acc.artists.push(curr);
        return acc;
      }

      if (AUTHOR_ROLES.includes(curr.role as AUTHOR_ROLE)) {
        acc.authors.push(curr);
        return acc;
      }

      acc.participants.push(curr);
      return acc;
    },
    {
      artists: [],
      participants: [],
      authors: [],
    } as PerformerRoles
  );

  return {
    artists: performers.artists,
    participants: performers.participants,
    authors: performers.authors,
  };
};

const toDistribution = (
  distributionResponse?: ReleaseDistributionResponse | null
): Distribution | undefined => {
  if (!distributionResponse) return;
  const { dsps } = distributionResponse;

  const distribution: Distribution = {
    dsps,
  };
  return distribution;
};

export const toTrack = (trackResponse?: TrackResponse): Track | undefined => {
  if (!trackResponse) return;

  const {
    id,
    idea,
    title,
    genre1,
    genre2,
    participants = [],
    originalReleaseDate,
    audio,
    recordingRight,
    originCountry,
    language,
    isrc,
    iswc,
    instrumental,
    explicit,
    liveRecording,
    trackStatus,
    updatedAt,
    releaseIds,
    preListeningStart,
    workId,
    gemaWorkNumber,
    gemaWorkVersionNumber,
    gemaWorkTitle,
    artificialIntelligence,
  } = trackResponse;

  const {
    originalFileName,
    storageFileName,
    fileSizeInBytes,
    fileUri,
    fileHash,
    durationSeconds,
    uploadingUserId,
    createdAt,
  } = audio || {};

  const { artists, authors, participants: performers } = toPerformerRoles(
    participants
  );

  const track: Track = {
    id,
    idea,
    gemaWorkNumber,
    gemaWorkVersionNumber,
    gemaWorkTitle,
    artificialIntelligence,
    genre1: genre1 || undefined,
    isrc: isrc || undefined,
    iswc: iswc || undefined,
    title: toTitle(title) || undefined,
    genre2: genre2 || undefined,
    participants: performers || undefined,
    artists: artists || undefined,
    authors: authors || undefined,
    originalReleaseDate: normalizedDate(originalReleaseDate) || undefined,
    audio: toFile({
      originalFileName,
      storageFileName,
      fileSizeInBytes,
      fileUri,
      fileHash,
      type: 'audio/wav',
      durationSeconds,
      uploadingUserId,
      createdAt,
    }),
    recordingRight: toCopyright(recordingRight),
    originCountry: originCountry || undefined,
    language: language || undefined,
    instrumental: instrumental || false,
    explicit: explicit || false,
    liveRecording: liveRecording || false,
    trackStatus: trackStatus,
    updatedAt: normalizedDate(updatedAt) || undefined,
    releaseIds: releaseIds || undefined,
    preListeningStart: preListeningStart
      ? dayjs()
          .startOf('day')
          .add(preListeningStart, 'seconds')
      : undefined,
    workId: workId || undefined,
  };

  return track;
};

export const toArtwork = (
  artworkResponse?: ReleaseArtworkResponse
): Artwork | undefined => {
  if (!artworkResponse) return;
  const {
    originalFileName,
    storageFileName,
    fileSizeInBytes,
    artworkUri,
    resizedArtworkUri,
    uploadingUserId,
    createdAt,
  } = artworkResponse;

  const artwork: Artwork = {
    file: toFile({
      originalFileName,
      storageFileName,
      fileSizeInBytes,
      originalImgUri: artworkUri,
      resizedImgUri: resizedArtworkUri,
      type: 'image/jpeg',
      uploadingUserId,
      createdAt,
    }),
  };
  return artwork;
};

export const toRelease = (releaseResponse: ReleaseResponse): Release => {
  const {
    id,
    createdDate,
    title,
    originalReleaseDate,
    genre1,
    genre2,
    releasedate,
    linerNotes,
    artwork,
    distribution,
    releaseStatusResponse,
    submissionDate,
    territories,
    upc,
    copyright,
    recordingRight,
    userId,
  } = releaseResponse;

  const release: Release = {
    id,
    upc,
    userId,
    genre1,
    territories,
    createdDate: normalizedDate(createdDate),
    title: toTitle(title) || undefined,
    originalReleaseDate: normalizedDate(originalReleaseDate),
    genre2: genre2 || undefined,
    releaseDate: normalizedDate(releasedate),
    linerNotes: linerNotes || undefined,
    artwork: toArtwork(artwork),
    distribution: toDistribution(distribution),
    releaseStatus: releaseStatusResponse as Release['releaseStatus'],
    submissionDate: normalizedDate(submissionDate),
    copyright: toCopyright(copyright),
    recordingRight: toCopyright(recordingRight),
  };

  return release;
};

const releaseListItemResponseToRelease = (
  releaseItem?: ReleaseResponse
): Release | undefined => {
  if (!releaseItem || !releaseItem.id) return;
  return toRelease(releaseItem);
};

export const toReleaseList = (
  releaseListResponse: ReleaseListResponse | undefined
): ReleaseList | [] => {
  const releaseList = releaseListResponse?.releases?.reduce(
    (acc: ReleaseList, releaseListItemResponse: ReleaseResponse) => {
      const release = releaseListItemResponseToRelease(releaseListItemResponse);
      if (release) {
        acc.push(release);
      }
      return acc;
    },
    []
  );

  return releaseList ? releaseList : [];
};

export const toReleaseRecentList = (
  releaseRecentListResponse: ReleaseRecentListResponse | undefined
): ReleaseRecentList | [] => {
  const releaseList = releaseRecentListResponse?.releases?.reduce(
    (
      acc: ReleaseRecentList,
      releaseListItemResponse: ReleaseRecentResponse
    ) => {
      const release = {
        ...releaseListItemResponse,
        title: toTitle(releaseListItemResponse.title),
      };
      if (release) {
        acc.push(release);
      }
      return acc;
    },
    []
  );

  return releaseList ? releaseList : [];
};
