import React, {
  ReactElement,
  useEffect,
  FC,
  Suspense,
  useMemo,
  ComponentType,
} from 'react';
import {
  Route,
  Routes as RRDRoutes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import routesConfig, { RouteProps } from 'config/routes';
import { useAuth } from 'hooks/useAuth';
import { NotFoundError, StandardError } from 'components/error/Error';
import { FileUploadContextProvider } from 'components/upload/FileUploadContext';
import { useTracking } from 'tracking/tracking';
import SubscriptionModalContextProvider from 'features/subscriptions/SubscriptionModalContext';
import LocaleChangeListener from './LocaleChangeListener';
import TermsConditionsUpdated from 'features/terms-conditions/TermsUpdatedModal';
import { ConfigContextProvider } from 'features/config/ConfigContextProvider';
import PageLoader from 'components/page-loader/PageLoader';
import { Layout } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import Nav from 'components/nav/Nav';
import UserRoleModal from 'features/user-role/UserRoleModal';
import DeploymentVersionPrompt from 'features/deployment-version/DeploymentVersionPrompt';
import { validate } from 'uuid';
import { SharingReleaseLockProvider } from 'features/share-release/components/SharingReleaseLockContext';

/**
 * SCROLL TO WINDOW TOP ON ROUTE REDIRECT
 */
export const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const LoggedInRouteProviders: FC<React.PropsWithChildren<unknown>> = (
  props
) => {
  return (
    <>
      <LocaleChangeListener />
      <UserRoleModal />
      <TermsConditionsUpdated />
      <DeploymentVersionPrompt />
      <ConfigContextProvider>
        <FileUploadContextProvider>
          <SubscriptionModalContextProvider>
            <SharingReleaseLockProvider>
              <ErrorBoundary FallbackComponent={() => <StandardError />}>
                {props.children}
              </ErrorBoundary>
            </SharingReleaseLockProvider>
          </SubscriptionModalContextProvider>
        </FileUploadContextProvider>
      </ConfigContextProvider>
    </>
  );
};

const PrivateRoute = ({
  isLoggedIn,
  PageComponent,
}: {
  isLoggedIn: boolean;
  PageComponent: ComponentType<React.PropsWithChildren<unknown>>;
}): ReactElement => {
  const { search, pathname, state } = useLocation();

  const loginRedirectUrl = Boolean(search)
    ? `/login${`${search}&redirectUrl=${pathname}`}`
    : `/login?redirectUrl=${pathname}`;

  return isLoggedIn ? (
    <LoggedInRouteProviders>
      <PageComponent />
    </LoggedInRouteProviders>
  ) : (
    <Navigate
      key="redirect-to-login"
      to={
        pathname === '/' || state === 'logout'
          ? `/login${search}`
          : loginRedirectUrl
      }
    />
  );
};

const Routes = (): ReactElement => {
  const { user, isLoadingState } = useAuth();
  const tracking = useTracking();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname: urlPathname } = useLocation();

  useEffect(() => {
    const uuid = (location.pathname || '')
      .split('/')
      .find((value) => validate(value));

    tracking.trackEvent({
      event: 'PAGE_VISIT',
      url: location.pathname,
      search: location.search,
      ...(!!uuid ? { entity_id: uuid } : {}),
    });
  }, [location.pathname, location.search, tracking]);

  const handleClick: MenuClickEventHandler = (e): void => {
    const selectedKey = e.key as string;
    const idx = routesConfig.findIndex((item) => item.pageKey === selectedKey);
    navigate(routesConfig[idx].value);
  };

  const memoCurrentKey = useMemo(() => {
    switch (true) {
      case urlPathname === '/':
        return 'home';
      case urlPathname.indexOf('/release/') != -1:
        return 'releases';
      case urlPathname.indexOf('/tracks/') != -1:
        return 'tracks';
      case urlPathname.indexOf('/account/details') != -1:
        return 'profileDetails';
      case urlPathname.indexOf('/account/royalty-payouts') != -1:
        return 'royaltyPayouts';
      case urlPathname.indexOf('/account/subscription') != -1:
        return 'subscription';
      case urlPathname.indexOf('/bio-page') != -1:
        return 'bio-page';
      case urlPathname.indexOf('/royalties') != -1:
        return 'royalties';
      default:
        return urlPathname.substring(1);
    }
  }, [urlPathname]);

  const isPublicUrl = routesConfig.some(
    ({ isPublic, value }) => isPublic && value === urlPathname
  );

  if (isLoadingState) {
    return <PageLoader />;
  }

  return (
    <Layout
      className={user.isLoggedIn && !isPublicUrl ? 'ant-parent-layout' : ''}
    >
      {user.isLoggedIn && !isPublicUrl && (
        <Nav currKey={memoCurrentKey} handleClick={handleClick} />
      )}

      <Suspense fallback={<PageLoader />}>
        <ScrollToTop />
        <RRDRoutes>
          {/* Public routes */}
          {routesConfig
            .filter((route) => route.isPublic)
            .map(
              ({
                pageKey,
                value,
                page: PageComponent,
              }: RouteProps): React.ReactElement => (
                <Route key={pageKey} path={value} element={<PageComponent />} />
              )
            )}
          {/* Private routes */}
          {routesConfig
            .filter((route) => !route.isPublic && route.isParentRoute)
            .map(
              ({
                pageKey,
                value,
                page: PageComponent,
              }: RouteProps): React.ReactElement => (
                <Route
                  key={pageKey}
                  path={`${value}/*`}
                  element={
                    <PrivateRoute
                      isLoggedIn={user.isLoggedIn}
                      PageComponent={PageComponent}
                    />
                  }
                />
              )
            )}

          <Route path="*" element={<NotFoundError />} />
        </RRDRoutes>
      </Suspense>
    </Layout>
  );
};

export default Routes;
