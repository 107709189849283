import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import App from 'components/app/App';
import * as Sentry from '@sentry/react';
import 'index.css';
import '@fontsource/poppins/latin-400.css';
import '@fontsource/poppins/latin-500.css';
import '@fontsource/poppins/latin-600.css';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import weekday from 'dayjs/plugin/weekday';
import minMax from 'dayjs/plugin/minMax';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  BarElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';

window.Buffer = window.Buffer || require('buffer').Buffer;

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(minMax);

const ENV = process.env.REACT_APP_HOST_ENV || 'local';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_LOG_SECRET as string,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
  service: 'musichub-app',
  env: ENV,
});

datadogRum.init({
  applicationId: 'a6a18926-fcac-4811-b31e-09744f88876a',
  clientToken: 'pub15aa6ca128cbf931e48d2e6ea8743080',
  site: 'datadoghq.eu',
  service: 'music-hub-app',
  version: '1.0.0',
  env: ENV,
  trackUserInteractions: false,
  // Only send for deployed envs to limit sessions for cost purposes
  sessionSampleRate: ENV === 'local' ? 0 : 100,
  // This should link APM tracing to RUM metrics - https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
  allowedTracingUrls: [
    'https://mh-api.mymusichub.io',
    'https://mh-api.music-hub.com',
  ],
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: ENV,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Could not find the FirebaseUI widget element on the page.',
    'Fetch is aborted',
    'Failed to fetch',
    'NetworkError',
    'network error',
    'Load failed',
    'Request aborted',
    'The play() request was interrupted by a call to pause()',
    'aborted by the user agent',
  ],
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['musichub-app-key'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
});

Chart.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Annotation,
  Tooltip,
  Legend,
  Filler
);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />)
);
const node = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(node!);
root.render(<RouterProvider router={router} />);
