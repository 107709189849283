import useSWR from 'swr';
import { AxiosError } from 'axios';
import { ReleaseCount } from 'features/release/models/Release';
import releaseCountService from '../services/releaseCountService';

export interface UseReleaseCountReturn {
  releaseCount?: ReleaseCount;
}

export const useReleaseCount = (): UseReleaseCountReturn => {
  const { data: releaseCount } = useSWR<ReleaseCount, AxiosError<Error>>(
    '/releaseCount',
    () => releaseCountService.getReleaseCount()
  );

  return {
    releaseCount: releaseCount,
  };
};
