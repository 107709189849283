import Modal from 'component-library/src/modal/Modal';
import React from 'react';
import { reloadPage, getRefreshInterval } from './deploymentVersionPromptUtils';
import Typography from 'component-library/src/typography/Typography';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { mixpanel } from 'tracking/tracking';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { logging } from 'logging/logging';

export const fetchLastBuild = async (): Promise<Dayjs | null> => {
  try {
    const response = await axios.get(
      `/static/data/lastBuild.json?_bust=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0',
        },
      }
    );

    const lastBuild = response.data?.lastBuild;
    if (lastBuild) {
      return dayjs(lastBuild);
    } else {
      return null;
    }
  } catch (error) {
    logging.error({
      message: (error as Error).message,
      productArea: 'misc',
      messageContext: error as Error,
    });
    return null;
  }
};

const DeploymentVersionPrompt: React.FC = () => {
  const { t } = useTranslation();
  const currentBuildTime = React.useRef<Dayjs | undefined | null>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    const checkForLaterBuild = async () => {
      const latestBuildTime = await fetchLastBuild();
      if (!latestBuildTime || isModalOpen) return;
      if (!currentBuildTime.current) {
        currentBuildTime.current = latestBuildTime;
      }
      const isBuildLater = Boolean(
        currentBuildTime.current &&
          latestBuildTime.isAfter(currentBuildTime.current)
      );
      setIsModalOpen(isBuildLater);
    };
    const interval = setInterval(checkForLaterBuild, getRefreshInterval());
    checkForLaterBuild();
    return () => clearInterval(interval);
  }, [isModalOpen]);

  return (
    <Modal
      size="s"
      closable={true}
      trackEvent={mixpanel.track.bind(mixpanel)}
      testId="new-version-modal"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      headerText={t('newVersionAvailableModal.header')}
      footerButtons={[
        {
          children: t('newVersionAvailableModal.refreshButton'),
          onClick: reloadPage,
          testId: 'new-version-modal-button',
        },
      ]}
    >
      <Space size={8} direction="vertical">
        <Typography type="body" size="m">
          {t('newVersionAvailableModal.message1')}
        </Typography>
        <Typography type="body" size="m">
          {t('newVersionAvailableModal.message2')}
        </Typography>
      </Space>
    </Modal>
  );
};

export default DeploymentVersionPrompt;
