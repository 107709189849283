import React from 'react';
import Typography from 'component-library/src/typography/Typography';
import theme from 'component-library/src/theme/theme';
import Button from 'component-library/src/button/Button';
import SubscriptionFaqs from 'features/subscriptions/SubscriptionsFaqs';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import { useTranslation } from 'react-i18next';
import Tag from 'component-library/src/tag/Tag';
import { Link } from 'react-router-dom';
import { paths } from 'config/routes';
import { mixpanel } from 'tracking/tracking';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';
import SubscriptionPoints from './SubscriptionPoints';
import Modal from 'component-library/src/modal/Modal';

const SubscriptionsPricingModal: React.FC<React.PropsWithChildren<
  unknown
>> = () => {
  const { t } = useTranslation();
  const modalContext = useSubscriptionModalContext();

  return (
    <Modal
      onCancel={() => modalContext.dispatch({ type: 'close' })}
      variant="marketing"
      size="l"
      headerSize="l"
      trackEvent={mixpanel.track.bind(mixpanel)}
      headerText={t('subscriptions.pricingModal.heading')}
      open
      testId="subscription-pricing-modal"
      className="subscription-pricing-modal"
      scroll
    >
      <div className="subscriptions-pricing-subheading">
        <Typography size="s" type="headline" block>
          {t('subscriptions.pricingModal.subheading')}
        </Typography>
      </div>

      <div className="subscriptions-pricing-boxes">
        <div className="subscriptions-pricing-box">
          <Space direction="vertical" size={spacing.space8} className="w-100">
            <Typography
              size="xs"
              type="headline"
              weightLevel="500"
              color="secondary7"
              block
            >
              {t('subscriptions.pricingModal.monthlyPlan.heading')}
            </Typography>

            <Space direction="horizontal" align="baseline">
              <Typography type="headline" size="m" color="secondary7" block>
                {t('subscriptions.pricingModal.monthlyPlan.price')}
              </Typography>
              <Typography type="body" size="xs" color="secondary7" block>
                {t('subscriptions.pricingModal.monthlyPlan.period')}
              </Typography>
            </Space>

            <Typography type="body" size="s" color="neutral8" block>
              {t('subscriptions.pricingModal.monthlyPlan.info')}
            </Typography>

            <Link
              to={`${paths.subscription}?billingCycle=monthly`}
              data-testid="subscription-pricing-modal-button-monthly"
            >
              <Button
                className="mt-4"
                size="m"
                type="primary"
                onClick={() => {
                  modalContext.dispatch({ type: 'close' });
                  mixpanel.track({
                    event: 'SUBSCRIPTIONS_CTA',
                    element: 'pricing-modal-button',
                    value: 'monthly',
                  });
                }}
                trackEvent={mixpanel.track.bind(mixpanel)}
              >
                {t('subscriptions.pricingModal.monthlyPlan.cta')}
              </Button>
            </Link>
          </Space>
        </div>
        <div className="subscriptions-pricing-box">
          <Tag
            className="subscriptions-pricing-annual-tag"
            color={theme.colors.accent4}
          >
            {t(`subscriptions.pricingModal.annualPlan.savingTag`)}
          </Tag>
          <Space direction="vertical" size={spacing.space8} className="w-100">
            <Typography
              size="xs"
              type="headline"
              weightLevel="500"
              color="secondary7"
              block
            >
              {t(`subscriptions.pricingModal.annualPlan.heading`)}
            </Typography>

            <Space direction="horizontal" align="baseline">
              <Typography
                type="body"
                size="xs"
                color="secondary7"
                block
                customClass="text-strike-through"
              >
                {t(`subscriptions.pricingModal.annualPlan.previousPrice`)}
              </Typography>
              <Typography type="headline" size="m" color="secondary7" block>
                {t(`subscriptions.pricingModal.annualPlan.price`)}
              </Typography>
              <Typography type="body" size="xs" color="secondary7" block>
                {t(`subscriptions.pricingModal.annualPlan.period`)}
              </Typography>
            </Space>

            <Typography type="body" size="s" color="neutral8" block>
              {t(`subscriptions.pricingModal.annualPlan.info`)}
            </Typography>

            <Link
              to={`${paths.subscription}?billingCycle=annual`}
              data-testid="subscription-pricing-modal-button-annual"
            >
              <Button
                className="mt-4"
                size="m"
                type="primary"
                onClick={() => {
                  modalContext.dispatch({ type: 'close' });
                  mixpanel.track({
                    event: 'SUBSCRIPTIONS_CTA',
                    element: 'pricing-modal-button',
                    value: 'annual',
                  });
                }}
                trackEvent={mixpanel.track.bind(mixpanel)}
              >
                {t(`subscriptions.pricingModal.annualPlan.cta`)}
              </Button>
            </Link>
          </Space>
        </div>
      </div>

      <div className="subscriptions-steps-subheading">
        <Typography size="s" type="headline" block>
          {t('subscriptions.pricingModal.stepsTitle')}
        </Typography>
      </div>

      <SubscriptionPoints />

      <Space
        className="w-100 subscriptions-pricing-faqs-container"
        direction="vertical"
        size={{
          md: spacing.space40,
          xs: spacing.space16,
        }}
      >
        <div className="w-100 text-align-c">
          <Typography size="s" type="headline" block>
            {t('subscriptions.pricingModal.faqsHeading')}
          </Typography>
        </div>
        <div className="w-100 subscriptions-pricing-faqs">
          <SubscriptionFaqs />
        </div>
      </Space>
    </Modal>
  );
};

export default SubscriptionsPricingModal;
