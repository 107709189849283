import React from 'react';
import { ConfigProvider } from 'antd';
import theme from './theme';
import de_DE from 'antd/locale/de_DE';
import en_GB from 'antd/locale/en_GB';
import { defaultTheme } from 'antd/es/theme/context';

const ThemeProvider: React.FC<React.PropsWithChildren<{
  language: string;
}>> = ({ children, language }) => {
  return (
    <ConfigProvider
      theme={{
        ...defaultTheme,
        components: {
          Alert: {
            lineHeight: 1,
          },
          Anchor: {
            colorPrimary: theme.colors.secondary2,
          },
          Layout: {
            bodyBg: theme.colors.secondary1,
            headerPadding: 0,
            headerBg: theme.colors.secondary1,
            headerHeight: 0,
            padding: 0,
            siderBg: theme.colors.secondary1,
          },
          Drawer: {
            colorBgElevated: theme.colors.secondary1,
          },
          Menu: {
            itemHoverBg: 'none',
            itemMarginInline: 0,
            itemMarginBlock: 6,
            colorText: theme.colors.secondary6,
            itemSelectedColor: theme.colors.secondary6,
          },
          ColorPicker: {
            colorBorder: 'rgba(0, 0, 0, 0.04)',
          },
          Card: {
            padding: 32,
            headerHeight: 0,
          },
          Form: {
            labelHeight: 40,
          },
          Typography: {
            titleMarginTop: 0,
          },
          Input: {
            fontSizeLG: 14,
            fontSize: 14,
            borderRadius: 4,
            hoverBorderColor: theme.colors.primary5,
            activeBorderColor: theme.colors.primary5,
          },
          InputNumber: {
            fontSize: 14,
            fontSizeLG: 14,
          },
          Pagination: {
            colorPrimary: theme.colors.secondary4,
            colorPrimaryHover: theme.colors.secondary4,
            itemBg: theme.colors.transparent,
          },
          Spin: {
            colorPrimary: theme.colors.secondary4,
          },
          Steps: {
            fontSize: 14,
            fontSizeLG: 14,
          },
          Select: {
            fontSizeLG: 14,
            optionSelectedBg: theme.colors.primary1,
          },
          Switch: {
            colorPrimaryHover: '',
            colorTextTertiary: '',
          },
        },
        token: {
          borderRadius: 4,
          fontSize: 14,
          fontFamily: `'Poppins', 'Helvetica Neue', sans-serif`,
          fontSizeHeading1: 32,
          fontSizeHeading2: 32,
          fontSizeHeading3: 25,
          fontSizeHeading4: 23,
          fontSizeHeading5: 20,
        },
      }}
      locale={language === 'de' ? de_DE : en_GB}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
