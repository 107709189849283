import React, { useCallback, useMemo } from 'react';
import { MenuItemProps } from 'antd/lib';
import MenuItem from 'antd/lib/menu/MenuItem';
import Feature from 'components/feature/Feature';
import { useAuth } from 'hooks/useAuth';
import HeadwayWidget from '@headwayapp/react-widget';
import { ENGLISH_ACCOUNT, GERMAN_ACCOUNT } from './constants';
import { mixpanel } from 'tracking/tracking';
import { useTranslation } from 'react-i18next';

const NewsMenuItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const { isLoadingState, userProfile } = useAuth();

  const sendMixpanelEvent = useCallback((type: string) => {
    mixpanel.track({
      event: 'HEADWAY_NEWS_INTERACTION',
      type,
    });
  }, []);

  const headwayAccount = useMemo(() => {
    return userProfile?.locale === 'de' ? GERMAN_ACCOUNT : ENGLISH_ACCOUNT;
  }, [userProfile]);

  if (isLoadingState) {
    return null;
  }

  return (
    <Feature name="headway">
      <MenuItem
        {...props}
        className={`${props.className || ''} news-menu-item`}
      >
        <HeadwayWidget
          account={headwayAccount}
          id="headway-widget-badge"
          badgePosition="center-right"
          widgetPosition="center-right"
          onShowWidget={() => sendMixpanelEvent('show-widget-click')}
          onReadMore={() => sendMixpanelEvent('read-more-click')}
          onShowDetails={() => sendMixpanelEvent('show-details-click')}
          onHideWidget={() => sendMixpanelEvent('hide-widget-click')}
          translations={{
            title: t('news.title'),
            labels: {
              new: t('news.labels.new'),
              update: t('news.labels.update'),
              improvement: t('news.labels.improvement'),
            },
            readMore: t('news.readMore'),
            footer: t('news.footer'),
          }}
          trigger
        >
          {props.children}
        </HeadwayWidget>
      </MenuItem>
    </Feature>
  );
};

export default NewsMenuItem;
