import { mutate, cache } from 'swr';
import {
  toReleaseRecentList,
  toRelease,
  toReleaseList,
} from 'features/release/models/responses/ReleaseResponseData';
import { instance } from 'helper/api/api';
import trackService, {
  TrackService,
} from 'features/track/services/trackService';
import releaseTrackService, {
  ReleaseTrackService,
} from './releaseTrackService';
import {
  Release,
  ReleaseList,
  ReleasePatchRequest,
  ReleaseRequest,
  ReleaseIngestionStatusRequest,
  ReleaseRecentList,
  ReleaseLockFailureResponse,
  ReleaseListResponse,
  ReleaseResponse,
  ReleaseTitleResponse,
} from 'features/release/models/Release';
import { ApiSpec } from 'specs/api-spec';
import dayjs from 'dayjs';
import { Axios, AxiosError, AxiosResponse } from 'axios';
import { ReleasePreview } from 'models/ReleasePreview';
import { toReleaseRequestData } from '../models/requests/ReleaseRequestData';
import { SignedUploadUrlResponse } from 'models/SignedUploadUrlResponse';
import { v4 as UUID } from 'uuid';
import { MHErrors } from 'models/MHErrors';
import { TrackTitle } from '../../track/models/Track';

export interface AllReleasesList {
  releases: ReleaseList;
  meta?: ApiSpec['schemas']['PaginationMetaResponse'];
}

export interface AllReleaseRecentList {
  releases: ReleaseRecentList;
  meta?: ApiSpec['schemas']['PaginationMetaResponse'];
}

export class ReleaseService {
  releaseServiceInstance: Axios;
  trackService: TrackService;
  releaseTrackService: ReleaseTrackService;

  constructor(
    releaseServiceInstance: Axios,
    trackService: TrackService,
    releaseTrackService: ReleaseTrackService
  ) {
    this.releaseServiceInstance = releaseServiceInstance;
    this.trackService = trackService;
    this.releaseTrackService = releaseTrackService;
  }

  apiEndpoint = '/releases';

  async getRelease(id: string): Promise<Release | undefined> {
    const cacheKey = [this.apiEndpoint, id];
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey) as Release;
    }
    return this.releaseServiceInstance
      .get(`${this.apiEndpoint}/${id}`)
      .then((res) => toRelease(res.data))
      .then((release) => {
        mutate(cacheKey, release);
        return release;
      });
  }

  async updateRelease(
    id: string,
    release: Release
  ): Promise<Release | undefined> {
    return this.releaseServiceInstance
      .put(`${this.apiEndpoint}/${id}`, toReleaseRequestData(release))
      .then((res) => toRelease(res.data));
  }

  async getAllReleases(
    page: number,
    size: number,
    ingestionStatus?: ReleaseIngestionStatusRequest
  ): Promise<AllReleasesList> {
    const releaseList = await this.releaseServiceInstance.get(
      `${this.apiEndpoint}?page=${page}&size=${size}${
        ingestionStatus ? `&ingestionStatus=${ingestionStatus}` : ''
      }`
    );
    return {
      releases: releaseList.data.releases
        ? toReleaseList(releaseList.data)
        : [],
      meta: releaseList.data.meta,
    };
  }

  async getReleaseList(filterIds?: string[]) {
    if (!filterIds || !filterIds.length) return [];

    const releasePromises: Promise<
      Release | undefined
    >[] = filterIds.map((id) => this.getRelease(id));
    return Promise.all(releasePromises) as Promise<(Release | undefined)[]>;
  }

  async createRelease(release: ReleaseRequest): Promise<Release | undefined> {
    const res = await this.releaseServiceInstance.post(
      this.apiEndpoint,
      release
    );
    return toRelease(res.data);
  }

  async patchRelease(
    releaseId: string,
    releasePatchRequest: ReleasePatchRequest
  ): Promise<ReleaseResponse> {
    const res = await this.releaseServiceInstance.patch<ReleaseResponse>(
      `${this.apiEndpoint}/${releaseId}`,
      releasePatchRequest
    );
    return res.data;
  }

  async deleteRelease(releaseId: string): Promise<ReleaseListResponse> {
    const { data } = await this.releaseServiceInstance.delete<
      ReleaseListResponse
    >(`${this.apiEndpoint}/${releaseId}`);
    return data;
  }

  async submitRelease(releaseId: string): Promise<unknown> {
    const { data } = await this.releaseServiceInstance.post(
      `${this.apiEndpoint}/${releaseId}/submission`
    );
    return data;
  }

  async lockReleaseForEditing(
    releaseId: string
  ): Promise<ReleaseLockFailureResponse | undefined> {
    try {
      const response = await this.releaseServiceInstance.post(
        `${this.apiEndpoint}/${releaseId}/lock`
      );
      return response.data;
    } catch (e) {
      const errorResponse = e as AxiosError;
      return errorResponse.response?.data as ReleaseLockFailureResponse;
    }
  }

  async unlockReleaseForEditing(releaseId: string): Promise<void> {
    // Always create a lock before releasing it to ensure lock to un-lock is sequential.
    // If the create is still pending the unlock will silently succeed but fail to unlock.
    await this.lockReleaseForEditing(releaseId);
    await this.releaseServiceInstance.delete(
      `${this.apiEndpoint}/${releaseId}/lock`
    );
  }

  async unlockActiveReleaseForEditing(url: string): Promise<void> {
    const regex = /\/release\/([^/]+)\/edit(\/.+)?$/;
    const match = url.match(regex);

    if (match) {
      const releaseId = match[1];
      await this.unlockReleaseForEditing(releaseId);
    }
  }

  async createReleaseFromTrack(trackId: string): Promise<Release | undefined> {
    const year = dayjs().year();
    const track = await trackService.getTrack(trackId);
    const release = await this.createRelease({
      title: this.trackTitleToReleaseTitleRequest(track?.title),
      genre1: track?.genre1,
      genre2: track?.genre2,
      copyright: { name: track?.recordingRight?.name || '', year },
      recordingRight: { name: track?.recordingRight?.name || '', year },
    });
    if (!release?.id || !track) return;
    await releaseTrackService.updateTrackList(release?.id, [track]);
    return release;
  }

  trackTitleToReleaseTitleRequest = (
    title?: TrackTitle
  ): ReleaseTitleResponse | undefined => {
    return title
      ? {
          title: title.title,
          language: title.language,
          version: title.version,
          remasteredYear: title.remasteredYear?.year(),
        }
      : undefined;
  };

  async getReleaseRecentList(
    page: number,
    size: number
  ): Promise<AllReleaseRecentList> {
    const releaseRecentList = await this.releaseServiceInstance.get(
      `${this.apiEndpoint}/recent?page=${page}&size=${size}`
    );

    return {
      releases: releaseRecentList.data.releases
        ? toReleaseRecentList(releaseRecentList.data)
        : [],
      meta: releaseRecentList.data.meta,
    };
  }

  async getReleasePreview(id: string): Promise<ReleasePreview> {
    const { data } = await this.releaseServiceInstance.get<ReleasePreview>(
      `${this.apiEndpoint}/${id}`,
      {
        headers: {
          Accept: 'application/x.musichub.releasepreview+json',
        },
      }
    );
    return data;
  }

  async getReleaseValidation(id: string): Promise<AxiosResponse<MHErrors>> {
    const res = await this.releaseServiceInstance.get<MHErrors>(
      `${this.apiEndpoint}/${id}/validation`
    );
    return res;
  }

  async generateSignedArtworkUrl(id: string): Promise<SignedUploadUrlResponse> {
    const { data } = await this.releaseServiceInstance.post<
      SignedUploadUrlResponse
    >(`/releases/${id}/artworkSignedUploadUrl?uuid=${UUID()}`);
    return data;
  }
}

export default new ReleaseService(instance, trackService, releaseTrackService);
