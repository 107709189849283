import { instance } from 'helper/api/api';
import { CombinedTrackReleaseStatus } from 'helper/constants/CombinedTrackReleaseStatus';
import {
  Track,
  TracklistPaginated,
  TrackRequest,
  TrackResponse,
  TrackParticipantResponse,
  TrackAudioWavelyzerFeedbackResponse,
  TrackAudioResponse,
  TrackAudioRequest,
  TrackPatchRequest,
  TrackForm,
} from 'features/track/models/Track';
import { toTrackRequestData } from 'features/track/models/requests/TrackRequestData';
import { toTrack } from 'features/release/models/responses/ReleaseResponseData';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import { Diff, diff } from 'deep-diff';
import { DuplicateTrackError } from 'errors/trackErrors';
import { ParticipantRequest } from 'features/participants/models/Participant';
import intercomService from '../../../services/intercomService';
import { AlgoliaTrack } from 'features/track/hooks/useTrackSearch.types';
import axios, { Axios, AxiosError } from 'axios';
import releaseTrackService, {
  ReleaseTrackService,
} from 'features/release/services/releaseTrackService';
import { SignedUploadUrlResponse } from 'models/SignedUploadUrlResponse';
import { v4 as UUID } from 'uuid';

export class TrackService {
  trackServiceInstance: Axios;
  releaseTrackService: ReleaseTrackService;

  constructor(
    trackServiceInstance: Axios,
    releaseTrackService: ReleaseTrackService
  ) {
    this.trackServiceInstance = trackServiceInstance;
    this.releaseTrackService = releaseTrackService;
  }

  apiEndpoint = '/tracks';

  trackUpdateAllowed(track: Track): boolean {
    return !(
      track.trackStatus === CombinedTrackReleaseStatus.AVAILABLE ||
      track.trackStatus === CombinedTrackReleaseStatus.PENDING ||
      track.trackStatus === CombinedTrackReleaseStatus.APPROVED
    );
  }

  async getTracks(
    statuses: Track['trackStatus'][] = [],
    page: number,
    size: number,
    search: string
  ): Promise<TracklistPaginated> {
    const tracks = await this.trackServiceInstance.get(
      `${this.apiEndpoint}?page=${page}&size=${size}${
        search ? `&search=${search}` : ''
      }${statuses.length ? `&status=${statuses.join(',')}` : ''}`
    );
    return {
      tracks: tracks?.data?.tracks
        ? (tracks?.data?.tracks.map(toTrack) as Track[])
        : [],
      meta: tracks.data.meta,
    };
  }

  async getTrack(trackId: string): Promise<Track | undefined> {
    const trackResp = await this.trackServiceInstance.get(
      `${this.apiEndpoint}/${trackId}`
    );
    return toTrack(trackResp?.data);
  }

  async createTrack(
    track: Track,
    releaseId?: string
  ): Promise<Track | undefined> {
    const releaseRequest = toTrackRequestData(track, false) as TrackRequest;
    let newTrack: TrackResponse | undefined;
    // If within the context of a shared release and not the owner
    // create the track under the release id so it belongs to the creator
    if (releaseId) {
      newTrack = await releaseTrackService
        .createReleaseTrack(releaseId, track)
        .then((it) => it.track);
    } else {
      newTrack = (
        await this.trackServiceInstance.post(this.apiEndpoint, releaseRequest)
      ).data;
    }

    const audio = releaseRequest.audio
      ? await this.setTrackAudio(newTrack?.id as string, releaseRequest.audio)
      : undefined;
    if (releaseRequest.audio) {
      intercomService.track('TRACK_AUDIO_ADD', {
        trackId: newTrack?.id,
        audioFilename: releaseRequest.audio.originalFileName,
      });
    }
    return toTrack({ ...newTrack, audio, participants: [] });
  }

  async updateTrack(
    id: string,
    track: Track,
    previousTracks?: Dictionary<Track>
  ): Promise<Track | undefined> {
    if (!this.trackUpdateAllowed(track)) {
      return track;
    }
    const participantsRegex = /authors|artists|participants/gim;
    const trackRequest = toTrackRequestData(track, false) as TrackRequest;
    let audio = trackRequest.audio;
    let participants = trackRequest.participants;
    let changes: Diff<TrackRequest, TrackRequest>[] | undefined = [];
    let updatedTrack = track as TrackResponse;
    let hasPrevious = false;

    if (previousTracks && track.id && previousTracks[track.id]) {
      const previousTrack = toTrackRequestData(
        previousTracks[track.id],
        false
      ) as TrackRequest;
      hasPrevious = true;
      changes = diff(trackRequest, previousTrack)?.filter(
        (diff) =>
          !diff.path?.join('.').includes('.position') &&
          !(
            diff.path?.join('.').includes('preListeningStart') &&
            previousTrack.preListeningStart === undefined &&
            trackRequest.preListeningStart === 0
          )
      );
    }

    try {
      // If there are changes to the track update it
      if (!hasPrevious || changes?.length) {
        updatedTrack = await this.trackServiceInstance
          .put(`${this.apiEndpoint}/${id}`, trackRequest)
          .then(({ data }) => data);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error.message.includes('409')) {
        throw new DuplicateTrackError(id);
      }
      throw e;
    }

    const changesOnParticipants = (changes || []).filter(
      (diffField) =>
        diffField.path && diffField.path[0].match(participantsRegex)
    );
    const changesOnAudio = (changes || []).filter(
      (diffField) =>
        (diffField.path || []).join('.') === 'audio.storageFileName' ||
        (diffField.path || []).join('.') === 'audio'
    );

    // If there are changes to the track participants update them
    if (!hasPrevious || !!changesOnParticipants.length) {
      participants = await this.setParticipants(
        updatedTrack?.id as string,
        trackRequest.participants || []
      );
    }

    // If there are changes to the track audio or it's being deleted (only using fileName here to prevent updates)
    if (
      !!changesOnAudio.length ||
      (track.id && !!previousTracks?.[track.id]?.audio && !audio)
    ) {
      intercomService.track('TRACK_AUDIO_ADD', {
        trackId: updatedTrack.id,
        audioFilename: trackRequest?.audio?.originalFileName,
      });
      audio = await this.setTrackAudio(
        updatedTrack?.id as string,
        trackRequest.audio
      );
    }

    return toTrack({
      ...updatedTrack,
      audio: audio,
      participants,
    });
  }

  async patchTrack(
    id: string,
    track: TrackPatchRequest
  ): Promise<Track | undefined> {
    const res = await this.trackServiceInstance.patch(
      `${this.apiEndpoint}/${id}`,
      track
    );
    return toTrack(res.data);
  }

  async updateTracks(
    tracks: Track[],
    previousTracks: Track[]
  ): Promise<(Track | undefined)[]> {
    const prevTrackMap = previousTracks.length
      ? keyBy(previousTracks, 'id')
      : undefined;
    return Promise.all(
      tracks.map((track) =>
        this.updateTrack(track.id as string, track, prevTrackMap)
      )
    ) as Promise<(Track | undefined)[]>;
  }

  async deleteTrack(track: Track | undefined): Promise<void> {
    if (!track || !track?.id) return;
    await this.trackServiceInstance.delete(`${this.apiEndpoint}/${track.id}`);
  }

  async deleteAlgoliaTrack(track: AlgoliaTrack | undefined): Promise<void> {
    if (!track || !track?.objectID) return;
    await this.trackServiceInstance.delete(
      `${this.apiEndpoint}/${track.objectID}`
    );
  }

  async deleteTrackAudio(trackId: string): Promise<void> {
    if (!trackId) return;
    await this.trackServiceInstance.delete(
      `${this.apiEndpoint}/${trackId}/audio`
    );
  }

  async getTrackAudio(id: string): Promise<TrackAudioResponse | undefined> {
    const newTrack = await this.trackServiceInstance.get(
      `${this.apiEndpoint}/${id}/audio`
    );
    return newTrack.data;
  }

  async setTrackAudio(
    id: string,
    audio?: TrackAudioRequest
  ): Promise<TrackAudioResponse | undefined> {
    if (!audio) {
      try {
        await this.deleteTrackAudio(id);
        return undefined;
      } catch (e) {
        return undefined;
      }
    }
    const trackAudio = await this.trackServiceInstance.post(
      `${this.apiEndpoint}/${id}/audio`,
      audio
    );
    return trackAudio.data;
  }

  async setParticipants(
    id: string,
    participants: ParticipantRequest[]
  ): Promise<TrackParticipantResponse[] | undefined> {
    const trackParticipants = await this.trackServiceInstance.post(
      `${this.apiEndpoint}/${id}/participants`,
      participants
    );
    return trackParticipants.data;
  }

  async getTrackWavealyzerFeedback(
    trackId: string
  ): Promise<TrackAudioWavelyzerFeedbackResponse | undefined> {
    const trackResp = await this.trackServiceInstance.get(
      `${this.apiEndpoint}/${trackId}/wavealyzerResults`
    );
    return trackResp?.data;
  }

  async copyTrackFromTo(from: Track, to: Track): Promise<void> {
    if (this.trackUpdateAllowed(to)) {
      await this.updateTrack(
        to.id as string,
        {
          ...from,
          id: to.id,
          title: {
            ...from.title,
            title: to.title?.title || '',
          },
          audio: to.audio,
          isrc: to.isrc || undefined,
          iswc: to.iswc || undefined,
        },
        {
          [to.id as string]: { ...to },
        }
      );
    }
  }

  async generateSignedAudioUrl(
    trackId?: string,
    releaseId?: string
  ): Promise<SignedUploadUrlResponse> {
    const signedUrl = await this.trackServiceInstance.post(
      `${this.apiEndpoint}/audioSignedUploadUrl`,
      {},
      {
        params: {
          uuid: UUID() /* Safari prevents re-requesting this endpoint without interval so adding a uuid to ensure a unique query */,
          trackId: trackId,
          releaseId: releaseId,
        },
      }
    );
    return signedUrl.data;
  }

  async getTrackAudioBlob(fileUri: string): Promise<Blob> {
    const trackAudioBlob = await axios.get(fileUri, {
      responseType: 'blob',
    });
    return trackAudioBlob.data;
  }

  async uploadAudioFile(id: string, targetUrl: string): Promise<void> {
    return this.trackServiceInstance.post(
      `${this.apiEndpoint}/${id}/audioUpload`,
      {
        targetUrl,
      }
    );
  }

  async storeSoundfileUploadId(
    trackId: string,
    soundfileId: string
  ): Promise<void> {
    return this.trackServiceInstance.post(
      `${this.apiEndpoint}/${trackId}/soundfileUpload`,
      {
        soundfileId: soundfileId,
      }
    );
  }

  async uploadCertificate(id: string): Promise<void> {
    return this.trackServiceInstance.post(
      `${this.apiEndpoint}/${id}/uploadCertificates`,
      {}
    );
  }

  isTrackEditable(track?: Track) {
    return (
      !!track?.trackStatus &&
      ['DRAFT', 'READY', 'RETURNED'].includes(track?.trackStatus)
    );
  }

  // toTrackRequest does not send all fields so this can be set for form reasons and ignored
  toTrackForm(track: Track): TrackForm {
    return {
      ...track,
      hasIsrc: Boolean(track.isrc),
      hasIswc: Boolean(track.iswc),
      isPreviouslyReleased: Boolean(track.originalReleaseDate),
    };
  }
}

export default new TrackService(instance, releaseTrackService);
