/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode } from 'react';
import { Select } from 'antd';
import i18next from 'i18next';
import {
  AvailableLocale,
  CLASSICAL_KEY_BASE,
  classicalVersionGuide,
  CLASSICAL_CROSS_OVER,
  ELECTRONIC_KEY_BASE,
  AUTHOR_ROLE,
  DELIVERY_LEAD_TIME_DAYS,
} from 'helper/constants/constants';
import keyBy from 'lodash/keyBy';
import { Release } from 'features/release/models/Release';
import { Track } from 'features/track/models/Track';
import { LanguagesEntry } from '../../features/config/Config';
import dayjs from 'dayjs';

// General
export interface SelectOption {
  value: string | number;
  key?: string | number;
  // Soon to be deprecated
  title: string;
  // New field for custom select - `title` should be deprecated when used in all locations
  label?: string;
  disabled?: boolean;
}

interface TreeSelectOption {
  value: string | number;
  title: string;
  children: [];
}

export const createOptions = (
  options: Array<SelectOption>,
  testIdPrefix = ''
): Array<ReactNode> =>
  options.map(({ title, value, key }) => (
    <Select.Option
      value={value}
      key={key || value}
      className="cy-option"
      data-testid={`${testIdPrefix}-${value}`}
    >
      {title}
    </Select.Option>
  ));

// Version Options:
const versions: Array<{
  [key: string]: string;
}> = require('helper/static/versions');

export const versionOptions = (): SelectOption[] =>
  versions
    .sort((a, b) => a.key.localeCompare(b.key))
    .map((versionObj) => ({
      value: versionObj.value,
      key: versionObj.key,
      title: versionObj.value,
    }));

const versionMap = keyBy(versions, 'key');
export const getVersion = (
  key: keyof typeof versionMap
): { [key: string]: string } => versionMap[key];

// Release genre Fields Tooltip Feedback:
export const releaseGenreTooltipMap = {
  [CLASSICAL_KEY_BASE]: [
    i18next.t('release.classicalGenreLabelTip'),
    '',
    classicalVersionGuide[i18next.languages[0]] ||
      classicalVersionGuide[AvailableLocale.EN],
  ],
};

/**
 * GENRE FUNCTIONS
 */

// Internal EMS / MH GENRE LIST
const mhGenres: Array<{
  [key: string]: string;
}> = require('helper/static/genres');

// GEMA WORKS GENRE LIST
const workGenres: Array<{
  [key: string]: string;
}> = require('helper/static/work-genres');

export const genreSelectOptions = (
  lang: string,
  type: 'recording' | 'works' = 'recording'
): SelectOption[] => {
  const genresToUse = type === 'works' ? workGenres : mhGenres;
  return genresToUse
    .sort((a, b) =>
      (a[lang] || a[AvailableLocale.EN]).localeCompare(
        b[lang] || b[AvailableLocale.EN]
      )
    )
    .map((it) => {
      const title = it[lang] || it[AvailableLocale.EN];
      return {
        value: it.code,
        title,
        label: title,
        disabled: it.disabled === 'true' ? true : undefined,
      };
    });
};

export const genreTreeOptions = (
  lang: string,
  type: 'recording' | 'works' = 'recording'
): Array<TreeSelectOption> => {
  const map = new Map();
  const genresToUse = type === 'works' ? workGenres : mhGenres;

  for (const genre of genresToUse) {
    const res = {
      children: [],
      title: genre[lang] || genre[AvailableLocale.EN],
      parent: genre.parent,
      value: genre.code,
      'data-testid': genre.code,
    };
    map.set(genre.code, res);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_key, value] of map) {
    const parent = value.parent;
    if (parent) {
      const parentObj = map.get(parent);
      parentObj.children.push(value);
    }
  }

  for (const [_key, value] of map) {
    const parent = value.parent;
    if (parent) map.delete(_key);
    delete value.parent;
  }
  return [...map.values()];
};

const mhGenresByCode = keyBy(mhGenres, 'code');
export const mhToGemaGenreMapping = (
  genreCode?: string
): string | undefined => {
  if (
    !Boolean(genreCode) ||
    genreCode === undefined ||
    !Boolean(genreCode in mhGenresByCode)
  ) {
    return undefined;
  }

  return mhGenresByCode[genreCode]['gema_map'];
};

// GEMA WORKS INSTRUMENTATIONS LIST
const workInstrumentations: Array<{
  [key: string]: string;
}> = require('helper/static/work-instrumentations');

export const instrumentationOptions = (lang = 'en'): Array<SelectOption> => {
  const langKey = lang === 'de' ? 'de' : 'en';

  return workInstrumentations
    .map((instrumentation) => ({
      value: instrumentation.code,
      title: instrumentation[langKey],
      label: instrumentation[langKey],
    }))
    .sort((a, b) => a.label?.localeCompare(b.label));
};

/**
 * Check if a Resource (Release|Track) is set with a classical genre
 * @param resource Release or Track Resource to check genre value of
 * @param options IsClassicalGenreResourceConfig
 * {
 *   includeCrossovers: Optional (default true) - Whether to include Classical Crossover type genres
 * }
 * @returns boolean
 */

interface IsClassicalGenreResourceConfig {
  includeCrossovers?: boolean;
}

export const isClassicalGenreResource = (
  resource?: Partial<Release> | Partial<Track>,
  options: IsClassicalGenreResourceConfig = {}
): boolean | undefined => {
  if (resource === undefined) {
    return;
  }
  const { includeCrossovers = true } = options;

  const { genre1 } = resource;
  const keyBaseCheck = includeCrossovers
    ? genre1?.startsWith(CLASSICAL_KEY_BASE) ||
      genre1?.startsWith(CLASSICAL_CROSS_OVER)
    : genre1?.startsWith(CLASSICAL_KEY_BASE) &&
      !genre1?.startsWith(CLASSICAL_CROSS_OVER);

  return genre1 !== undefined && genre1 !== null && keyBaseCheck;
};

export const isElectronicGenreRelease = (
  resource?: Partial<Release> | Partial<Track>
): boolean | undefined => {
  if (resource === undefined) {
    return;
  }

  const { genre1 } = resource;
  const keyBaseCheck = genre1?.startsWith(ELECTRONIC_KEY_BASE);

  return genre1 !== undefined && genre1 !== null && keyBaseCheck;
};

export const getDistributionLeadTime = (
  classicalReleaseSet: boolean | undefined,
  release: Release | undefined
) => {
  const normalLeadTime = classicalReleaseSet
    ? DELIVERY_LEAD_TIME_DAYS.classical
    : DELIVERY_LEAD_TIME_DAYS.nonClassical;

  if (release?.releaseStatus?.combinedStatus === 'RETURNED') {
    if (
      dayjs(release.releaseDate).isBefore(
        dayjs().add(DELIVERY_LEAD_TIME_DAYS.returned, 'days')
      )
    ) {
      return DELIVERY_LEAD_TIME_DAYS.returned;
    }
    if (dayjs(release.releaseDate).diff(dayjs(), 'day') + 1 < normalLeadTime) {
      return dayjs(release.releaseDate).diff(dayjs(), 'day') + 1;
    }
  }
  return normalLeadTime;
};

/**
 * LANGUAGE FUNCTIONS
 */

// Language options
export const languageOptions = (
  languages: LanguagesEntry
): Array<SelectOption> => {
  return Object.entries(languages)
    .map(([value, title]) => ({
      value,
      title,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
};

// Country options
const countries = require('helper/static/countries');
export const countryOptions = (lang = 'en'): Array<SelectOption> => {
  const langKey = lang === 'de' ? 'de' : 'en';

  return Object.keys(countries[langKey])
    .map((countryKey) => ({
      value: countryKey,
      title: countries[langKey][countryKey],
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getDisabledRoleStatus = (roleId: string, track?: Track) => {
  if (track?.instrumental === true && roleId === AUTHOR_ROLE.LYRICIST)
    return true;
  return false;
};

export const getPerformerRoleOptions = (
  roles: Array<{ id: string; value: string }>,
  track?: Track
): Array<SelectOption> =>
  roles
    .map(
      (role: { id: string; value: string }): SelectOption => ({
        value: role.id,
        title: role.value,
        label: role.value,
        disabled: getDisabledRoleStatus(role.id, track),
      })
    )
    .sort((a, b) => a.title.localeCompare(b.title));

// Filter used in Select input search
export const selectSearchFilter = (inputValue: string, option: any): boolean =>
  option &&
  option.children
    .toString()
    .toLowerCase()
    .includes(inputValue.toLowerCase());

export const releasesInStoresOptions: Array<SelectOption> = [
  { title: '0-4', value: '0-4' },
  { title: '5-9', value: '5-9' },
  { title: '10-14', value: '10-14' },
  { title: '15-19', value: '15-19' },
  { title: '20 ' + i18next.t('userSegmentation.orMore'), value: '20-30' },
];

export const plannedReleasesOptions: Array<SelectOption> = [
  { title: '0-4', value: '0-4' },
  { title: '5-9', value: '5-9' },
  { title: '10-14', value: '10-14' },
  { title: '15-19', value: '15-19' },
  { title: '20 ' + i18next.t('userSegmentation.orMore'), value: '20-30' },
];

export const incomeFromMusicOptions: Array<SelectOption> = [
  { title: '0-19%', value: '0-19' },
  { title: '20-39%', value: '20-39' },
  { title: '40-59%', value: '40-59' },
  { title: '60-79%', value: '60-79' },
  { title: '80-100%', value: '80-100' },
];

export const ageRangeOptions: Array<SelectOption> = [
  { title: '18-24', value: '18-24' },
  { title: '25-34', value: '25-34' },
  { title: '35-44', value: '35-44' },
  { title: '45-54', value: '45-54' },
  { title: '55-64', value: '55-64' },
  { title: '65-74', value: '65-74' },
  { title: '75 ' + i18next.t('userSegmentation.orOlder'), value: '75-100' },
];

export const gemaMembershipLengthOptions: Array<SelectOption> = [
  {
    value: '0-1',
    title: `${i18next.t('userSegmentation.years', { range: '0-1' })}`,
  },
  {
    value: '1-2',
    title: `${i18next.t('userSegmentation.years', { range: '1-2' })}`,
  },
  {
    value: '2-3',
    title: `${i18next.t('userSegmentation.years', { range: '2-3' })}`,
  },
  {
    value: '3-5',
    title: `${i18next.t('userSegmentation.years', { range: '3-5' })}`,
  },
  {
    value: '5-10',
    title: `${i18next.t('userSegmentation.years', { range: '5-10' })}`,
  },
  {
    value: '10-20',
    title: `${i18next.t('userSegmentation.years', { range: '10-20' })}`,
  },
  {
    value: '20-100',
    title: `${i18next.t('userSegmentation.years', { range: '20' })} ${i18next.t(
      'userSegmentation.orMore'
    )}`,
  },
];

export const releaseIsNotEdit = (release?: Release) => {
  if (release?.releaseStatus?.combinedStatus === undefined) {
    return null;
  }

  return ['PENDING', 'APPROVED', 'AVAILABLE', 'IN_REVIEW', 'LIVE'].includes(
    release?.releaseStatus?.combinedStatus
  );
};
