import { OnceExecutionPromiseManager } from 'helper/queue/OnceExecutionPromiseManager';
import { MusicAnalyticsToken } from 'features/stats/models/MusicAnalytics';
import api from 'helper/api/api';

const MUSIC_ANALYTICS_STORAGE_KEY = 'MUSIC_ANALYTICS_STORAGE_KEY';

export class MusicAnalyticsAuthentication {
  token?: MusicAnalyticsToken;
  private eventPromiseManager: OnceExecutionPromiseManager;

  constructor() {
    this.eventPromiseManager = new OnceExecutionPromiseManager(
      'get-token-success'
    );
    this.init();
  }

  private async init(): Promise<void> {
    const storedToken = localStorage.getItem(MUSIC_ANALYTICS_STORAGE_KEY);
    if (storedToken) {
      this.token = JSON.parse(storedToken) as MusicAnalyticsToken;
    }
  }

  /**
   * Stores the token in local storage
   * @param token
   * @private
   */
  private setToken(token: MusicAnalyticsToken): void {
    const tokenWithExpiresTs: MusicAnalyticsToken = {
      ...token,
      expires: new Date().getTime() + token.expires_in,
    };
    localStorage.setItem(
      MUSIC_ANALYTICS_STORAGE_KEY,
      JSON.stringify(tokenWithExpiresTs)
    );
    this.token = tokenWithExpiresTs;
  }

  /**
   * Gets a new token for EMS Music Analytics via our API.
   * Only calls the API if no token exists in local storage or the token is expired.
   * @private
   */
  private async _getMusicAnalyticsToken(): Promise<void> {
    if (!this.token || new Date().getTime() > this.token.expires) {
      const { data: musicAnalyticsToken } = await api.musicAnalytics.getToken();
      this.setToken(musicAnalyticsToken);
    }
  }

  /**
   * Uses OnceExecutionPromiseManager.once to get token to ensure that concurrent requests are handled and repeat requests to create auth tokens are avoided
   */
  public async getMusicAnalyticsToken(): Promise<string | undefined> {
    await this.eventPromiseManager.once(
      this._getMusicAnalyticsToken.bind(this)
    );
    return this.token?.access_token;
  }

  /**
   * Handles Sign Out logic: removes localStorage Key
   */
  public signOut(): void {
    localStorage.removeItem(MUSIC_ANALYTICS_STORAGE_KEY);
  }
}

export default new MusicAnalyticsAuthentication();
