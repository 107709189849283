import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';

export interface ImageCompProps {
  imageSrc?: string;
  imageAlt?: string;
  loadingType?: 'lazy' | 'eager';
  testId?: string;
  className?: string;
  fallbackImage: string | ReactElement | (string | ReactElement | undefined)[];
}

const ImageComp: FC<ImageCompProps> = ({
  imageSrc,
  imageAlt = '',
  loadingType = 'lazy',
  testId,
  className,
  fallbackImage,
}) => {
  const [fallbackIndex, setFallbackIndex] = useState(imageSrc ? -1 : 0); // Start at -1 if `imageSrc` exists, else start with fallback logic

  // Pre-resolve all valid fallback images or React elements into a flat array
  const resolvedFallbacks = useMemo(() => {
    const flattenFallbacks = (
      fallback: ImageCompProps['fallbackImage'] | undefined
    ): Array<string | ReactElement> => {
      if (!fallback) return [];
      if (Array.isArray(fallback)) {
        return fallback
          .flatMap((item) => flattenFallbacks(item))
          .filter((item) => item !== undefined);
      }
      return [fallback];
    };

    return flattenFallbacks(fallbackImage);
  }, [fallbackImage]);

  // Fallback handler to advance to the next fallback if the current one fails
  const handleFallbackError = () => {
    setFallbackIndex((prevIndex) => prevIndex + 1);
  };

  // Render the current fallback image or React element
  const renderCurrentFallback = useCallback((): ReactElement | null => {
    const currentFallback = resolvedFallbacks[fallbackIndex];

    if (!currentFallback) {
      return null;
    }

    if (typeof currentFallback === 'string') {
      return (
        <img
          src={currentFallback}
          alt={imageAlt}
          data-testid={testId}
          className={className}
          loading={loadingType}
          onError={handleFallbackError} // Advance to next fallback on error
        />
      );
    }

    if (React.isValidElement(currentFallback)) {
      return (
        <div data-testid={testId} className={className}>
          {currentFallback}
        </div>
      );
    }

    return null;
  }, [
    testId,
    imageAlt,
    className,
    loadingType,
    fallbackIndex,
    resolvedFallbacks,
  ]);

  if (!imageSrc || fallbackIndex >= 0) {
    return renderCurrentFallback();
  }

  return (
    <img
      src={imageSrc}
      alt={imageAlt}
      data-testid={testId}
      className={className}
      loading={loadingType}
      onError={handleFallbackError}
    />
  );
};

export default ImageComp;
