import { Release } from 'features/release/models/Release';
import { Track } from 'features/track/models/Track';

export const newRelease = (): Release => ({
  tracklist: [],
});

export const newTrack = (): Track => ({
  participants: [],
});
