import { instance } from 'helper/api/api';
import { Track } from 'features/track/models/Track';
import { toTrack } from 'features/release/models/responses/ReleaseResponseData';
import {
  ReleaseTrackRequest,
  ReleaseTrackResponse,
} from 'features/release/models/Release';
import { toTrackRequestData } from 'features/track/models/requests/TrackRequestData';
import { Axios } from 'axios';

interface ReleaseTrack {
  trackNumber: number | undefined;
  track: Track | undefined;
}

export class ReleaseTrackService {
  releaseTrackServiceInstance: Axios;
  apiEndpoint(id: string) {
    return `/releases/${id}/tracks`;
  }

  constructor(releaseTrackServiceInstance: Axios) {
    this.releaseTrackServiceInstance = releaseTrackServiceInstance;
  }

  sortTrackList(tracklist: ReleaseTrackResponse[]): ReleaseTrackResponse[] {
    return [...tracklist].sort(
      (a, b) => (a.trackNumber || -1) - (b.trackNumber || -1)
    );
  }

  tracksToReleaseTracksRequest(tracks: Track[]): ReleaseTrackRequest[] {
    return [...tracks].map((t, index) => ({
      trackId: t?.id as string,
      trackNumber: index + 1,
    }));
  }

  toReleaseTrack(releaseTrackResponse: ReleaseTrackResponse): ReleaseTrack {
    return {
      trackNumber: releaseTrackResponse.trackNumber,
      track: toTrack(releaseTrackResponse.track),
    };
  }

  toTracklist(releaseTracks: ReleaseTrack[]): Track[] {
    return releaseTracks.map((track) => ({
      ...track.track,
    }));
  }

  // Get all releaseTrack entities for a release by ID
  async getTrackList(id: string): Promise<ReleaseTrack[]> {
    const releaseTracks = await this.releaseTrackServiceInstance.get(
      this.apiEndpoint(id)
    );
    return this.sortTrackList(releaseTracks.data).map(
      this.toReleaseTrack.bind(this)
    );
  }

  // Update the releaseTrack list
  async updateTrackList(
    id: string,
    tracks: Track[]
  ): Promise<ReleaseTrackResponse[]> {
    const releaseTracks = await this.releaseTrackServiceInstance.put(
      this.apiEndpoint(id),
      this.tracksToReleaseTracksRequest(tracks)
    );
    return this.sortTrackList(releaseTracks.data);
  }

  async createReleaseTrack(
    id: string,
    track: Track
  ): Promise<ReleaseTrackResponse> {
    const releaseTrack = await this.releaseTrackServiceInstance.patch(
      this.apiEndpoint(id),
      toTrackRequestData(track, false)
    );
    return releaseTrack.data;
  }
}

export default new ReleaseTrackService(instance);
