import React from 'react';
import {
  musicHubAcademyLink,
  musicHubBlogLink,
} from 'helper/constants/constants';
import { AcademyHat, BalanceMenuIcon, Disc, TrackMenuIcon } from 'assets/img';
import {
  BarChartOutlined,
  HomeOutlined,
  LinkOutlined,
  ScheduleOutlined,
  UserOutlined,
  WalletOutlined,
  BulbOutlined,
  LayoutOutlined,
  SoundOutlined,
  TeamOutlined,
} from '@ant-design/icons';

export const externalLinks = [
  {
    linkKey: 'musicHubAcademyLink',
    navMenuName: 'MusicHub Academy',
    hrefVal: musicHubAcademyLink,
  },
  {
    linkKey: 'blogLink',
    navMenuName: 'Blog',
    hrefVal: musicHubBlogLink,
  },
];

export const subMenuIcons = {
  ['home' as string]: <HomeOutlined className="nav-menu-item-icon" />,
  ['news' as string]: <SoundOutlined className="nav-menu-item-icon" />,
  ['releases' as string]: <Disc className="nav-menu-item-icon" />,
  ['tracks' as string]: <TrackMenuIcon className="nav-menu-item-icon" />,
  ['stats' as string]: <BarChartOutlined className="nav-menu-item-icon" />,
  ['balance' as string]: <BalanceMenuIcon className="nav-menu-item-icon" />,
  ['royalties' as string]: <BalanceMenuIcon className="nav-menu-item-icon" />,
  ['profileDetails' as string]: <UserOutlined className="nav-menu-item-icon" />,
  ['royaltyPayouts' as string]: (
    <WalletOutlined className="nav-menu-item-icon" />
  ),
  ['subscription' as string]: (
    <ScheduleOutlined className="nav-menu-item-icon" />
  ),
  ['bio-page' as string]: <LinkOutlined className="nav-menu-item-icon" />,
  ['promo-partners' as string]: <TeamOutlined className="nav-menu-item-icon" />,
  ['musicHubAcademyLink' as string]: (
    <AcademyHat className="nav-menu-item-icon nav-menu-item-icon--with-stroke" />
  ),
  ['discordCommunityLink' as string]: (
    <BulbOutlined className="nav-menu-item-icon nav-menu-item-icon--with-stroke" />
  ),
  ['blogLink' as string]: (
    <LayoutOutlined className="nav-menu-item-icon nav-menu-item-icon--with-stroke" />
  ),
};
