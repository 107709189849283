import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import 'firebase/auth';
import { useTracking, mixpanel } from 'tracking/tracking';
import authentication, {
  MusicHubAuthUser,
} from 'helper/authentication/authentication';
import { getCurrentImpersonatedUser } from './useImpersonate';
import api from 'helper/api/api';
import { mutate } from 'swr';
import { UserProfile } from 'models/Users';
import { UseSubscriptionReturn, useSubscription } from './useSubscription';
import intercomService from 'services/intercomService';
import algoliaService from 'services/algoliaService';
import { AxiosError } from 'axios';
import { paths } from 'config/routes';
import { OKTA_FIREBASE_ERROR_QUERY_STRING } from 'helper/constants/constants';
import { logging } from 'logging/logging';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import cookies from 'helper/authentication/cookies';
import releaseService from 'features/release/services/releaseService';

type Props = {
  children: React.ReactNode;
};

export interface MusicHubUser extends MusicHubAuthUser {
  id?: string;
  isInternalUser: boolean;
  isLoggedIn: boolean;
  isGemaMember: boolean;
  isAdminImpersonating: boolean;
  // TODO: deprecate after pricing experiment
  pricingExperimentVariant?: 'A' | 'B';
}

export interface AuthInterface {
  user: MusicHubUser;
  userProfile?: UserProfile;
  userSubscription: UseSubscriptionReturn | undefined;
  isLoadingState: boolean;
  signout(): void;
}

const nonLoggedInUser: MusicHubUser = {
  email: undefined,
  isLoggedIn: false,
  isInternalUser: false,
  isAdminImpersonating: false,
  isGemaMember: false,
  pricingExperimentVariant: undefined,
};

const defaultAuth: AuthInterface = {
  user: nonLoggedInUser,
  userSubscription: undefined,
  isLoadingState: true,
  signout: () => ({}),
};

export const useProvideAuth = (): AuthInterface => {
  const [user, setUser] = useState<MusicHubUser>(nonLoggedInUser);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    undefined
  );
  const userSubscription = useSubscription(user);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(true);
  const tracking = useTracking();

  const getUserProfile = async (
    mhUser: MusicHubUser
  ): Promise<UserProfile | undefined> => {
    const impersonatedUser = getCurrentImpersonatedUser();
    if (impersonatedUser) {
      return {
        userId: impersonatedUser,
      } as UserProfile;
    }
    try {
      const userProfile = await api.account.getUserProfile();
      logging.info({
        productArea: 'auth',
        message: `User ${userProfile.data.userId} logging in`,
        messageContext: userProfile.data,
      });
      setUserProfile(userProfile.data);
      mutate('/userProfile', userProfile.data, false);
      return userProfile.data;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          logging.error({
            productArea: 'auth',
            message: `Gema user ${mhUser.email} with id ${mhUser.issuerId} attempting to login via firebase - Log out user and inform them`,
            messageContext: e.response,
            error: e as Error,
          });
          if (!window.parent.location.pathname.includes(paths.login)) {
            window.parent.location.href = `${paths.login}?errorType=${OKTA_FIREBASE_ERROR_QUERY_STRING}`;
          }
        }
      }
      return;
    }
  };

  const getPricingExperimentVariant = (): MusicHubUser['pricingExperimentVariant'] => {
    try {
      return (cookies.getCookie('pricing_experiment_variant') ||
        undefined) as MusicHubUser['pricingExperimentVariant'];
    } catch {
      return undefined;
    }
  };

  useEffect(() => {
    // Fallback ensures we do not get stuck without onAuthStateChanged - max load balancer timeout of 30s ensures test workaround and slow network do not conflict
    const fallbackTimeoutSeconds = 30;
    const fallbackTimeout = setTimeout(() => {
      logging.error({
        productArea: 'auth',
        message: `User ${authentication.user?.email ||
          'Unknown'} logged out because onAuthStateChanged wasn't fired or completed within the 30s max timeout`,
      });
      setIsLoadingState(false);
    }, fallbackTimeoutSeconds * 1000);

    // Listen for auth changes
    authentication.onAuthStateChanged(() => {
      clearTimeout(fallbackTimeout);
      const impersonatedUser = getCurrentImpersonatedUser();
      const authUser = authentication.user;

      if (authUser) {
        authentication.getFirebaseIdToken().then(async (token) => {
          if (!token) {
            return;
          }

          let musicHubUser: MusicHubUser = {
            ...authUser,
            isLoggedIn: true,
            isAdminImpersonating: Boolean(impersonatedUser),
            isGemaMember: false,
            isInternalUser: Boolean(
              authUser.email?.endsWith('@music-hub.com') ||
                authUser.email?.endsWith('@joinmusichub.de')
            ),
            pricingExperimentVariant: getPricingExperimentVariant(),
          };

          // If the user is unverified we want to set the user but prevent any calls from occuring from the use of this hook
          if (!musicHubUser.emailVerified) {
            setUser(musicHubUser);
            setIsLoadingState(false);
            return;
          }

          const userProfile = await getUserProfile(musicHubUser);

          musicHubUser = {
            ...musicHubUser,
            id: userProfile?.userId as string,
            isGemaMember: userProfile?.gemaUser ?? false,
          };

          authentication.setCrossOriginUserCache({
            userId: musicHubUser.id,
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
            isLoggedIn: true,
            accessToken: token,
          });

          if (impersonatedUser) {
            algoliaService.deleteAlgoliaKey();
          }

          const registerRedirectUrl = authentication.getExternalRedirectUrl();
          if (registerRedirectUrl) {
            logging.error({
              productArea: 'auth',
              message: `Redirecting user ${musicHubUser.id} to ${registerRedirectUrl}`,
            });
            authentication.removeExternalRedirectUrl();
            window.location.href = registerRedirectUrl;
            return;
          }

          setUser(musicHubUser);
          setIsLoadingState(false);
          mixpanel.identify(musicHubUser.id);
          if (!impersonatedUser) {
            intercomService.updateUser(musicHubUser, userProfile);
            mixpanel.setPeopleProperty(
              'IS_INTERNAL_USER',
              musicHubUser.isInternalUser
            );
            mixpanel.setPeopleProperty('MUSIC_HUB_USER_ID', musicHubUser.id);
            mixpanel.setPeopleProperty(
              'IS_GEMA_USER',
              musicHubUser.isGemaMember
            );
            mixpanel.setPeopleProperty(
              'PRICING_EXPERIMENT_VARIANT',
              musicHubUser.pricingExperimentVariant
            );
          }

          const logUserData = {
            id: musicHubUser.id,
          };
          datadogLogs.setUser(logUserData);
          datadogRum.setUser(logUserData);
        });
      } else {
        setUser(nonLoggedInUser);
        setIsLoadingState(false);
      }
    });

    return () => clearTimeout(fallbackTimeout);
  }, []);

  const signout = async (): Promise<void> => {
    await authentication.signOut();
    await releaseService.unlockActiveReleaseForEditing(window.location.href);
    setUser(nonLoggedInUser);
    intercomService.reset();
    tracking.trackEvent({
      event: 'USER_LOGOUT',
    });
    datadogLogs.clearUser();
    datadogRum.clearUser();
  };

  return {
    user: user,
    userProfile,
    isLoadingState: isLoadingState,
    signout: signout,
    userSubscription,
  };
};

const authContext = createContext<AuthInterface>(defaultAuth);

export const AuthProvider = ({ children }: Props): ReactElement => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): AuthInterface => {
  return useContext(authContext);
};
