import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import deepTrim from 'deep-trim';

import { MHProfileError } from 'models/MHErrors';
import { ReleaseResponse } from 'features/release/models/Release';
import authentication, {
  IssuerType,
} from 'helper/authentication/authentication';
import { MusicAnalyticsToken } from 'features/stats/models/MusicAnalytics';
import {
  SoundfileDocRequest,
  SoundfileDocResponse,
  SoundfileGetResponseBody,
} from 'features/track/track-tabs/track-broadcast-monitoring/models/SoundFile';
import isString from 'lodash/isString';
import {
  ParticipantListResponse,
  ParticipantRequest,
  ParticipantResponse,
} from 'features/participants/models/Participant';
import {
  UserFeatureResponse,
  UserProfile,
  UserProfilePatchRequest,
} from 'models/Users';
import { ParticipantRoles } from 'features/participants/hooks/useParticipantRoles';
import { ExperimentResponse } from 'models/Experiments';
import { SignedUploadUrlResponse } from 'models/SignedUploadUrlResponse';
import { logging } from 'logging/logging';
import { StatementLineItem } from 'features/royalties/models/Royalties';

const NON_AUTHENTICATED_ROUTES = [
  '/actuator/health',
  '/exchange?issuer=gema',
  '/exchange?issuer=gemaCiam',
  '/register',
  '/featureFlags',
];

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: 0,
});

export const axiosRequestMiddleware = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig<unknown>> => {
  if (config?.url && NON_AUTHENTICATED_ROUTES.includes(config.url)) {
    return config;
  }
  const user = authentication.user;
  if (!user) {
    // signOut is enough to trigger a redirect to the login page
    await authentication.signOut();
    return Promise.reject({ message: 'User session has expired' });
  }

  // gets impersonated user if available
  const impersonatedUser = localStorage.getItem('impersonatedUser');

  // Trim all extra whitespace from data strings
  if (
    config.method?.toLowerCase() === 'put' ||
    config.method?.toLowerCase() === 'post'
  ) {
    config.data = deepTrim(config.data);
  }

  // getIdToken also refreshes token if the access token has expired
  const authTokenBearer = await authentication.getFirebaseIdToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${authTokenBearer}`,
      ...(impersonatedUser && { 'X-Impersonate': impersonatedUser }),
    },
  };
};

instance.interceptors.request.use(axiosRequestMiddleware, (error) => {
  Sentry.captureException(error);
  datadogRum.addError(error);
  return Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.status && error.status >= 400 && error.status < 500) {
      Sentry.captureException(error);
      datadogRum.addError(error);
    }
    return Promise.reject(error);
  }
);

/*
 * File upload
 *
 * When uploading a file follow this order:
 * 1. Generate signed url
 * 2. Use sigened url to save file to gcp bucket
 */
const file = {
  saveToGCPBucket: (
    signedUrl: string,
    fileData: File,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    const { withCredentials, headers, onUploadProgress } = config;
    return axios.put(signedUrl, fileData, {
      withCredentials,
      headers: { ...headers },
      onUploadProgress,
    });
  },
};

/**
 * Royalties
 */
const royalties = {
  royaltyReport: (
    dsp: StatementLineItem['dsp'],
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> =>
    instance.get(
      `/royaltyReport?dsp=${dsp}&startDate=${startDate}&endDate=${endDate}`,
      {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }
    ),
};

/*
 * Payout
 */

interface SummaryParams {
  periodStart?: string;
  periodEnd?: string;
  topItemsLimit?: number;
  dspList?: Array<string>;
  territoryList?: Array<string>;
  trackId?: string;
  releaseId?: string;
}

const payout = {
  getSummary: (params: SummaryParams): Promise<AxiosResponse> =>
    instance.get(`/payouts/summary`, { params }),
};

/*
 * Account
 */

const account = {
  getUserProfile: (): Promise<AxiosResponse<UserProfile>> => {
    return instance.get('/userProfile');
  },

  getUserProfileValidations: (): Promise<AxiosResponse<MHProfileError[]>> => {
    return instance.get('/userProfile/validation');
  },

  setUserProfile: (
    profileData: UserProfile
  ): Promise<AxiosResponse<UserProfile>> => {
    // The API requires that all empty fields are null (i.e. not just emtpy white space)
    const keys: Array<keyof UserProfile> = Object.keys(profileData) as Array<
      keyof UserProfile
    >;
    const profileDataAsList = keys.map((profileAttribute) => {
      const currentProfileAttribute = profileData[profileAttribute];
      const updatedProfileAttribute =
        isString(currentProfileAttribute) && !!currentProfileAttribute?.trim()
          ? currentProfileAttribute?.trim()
          : currentProfileAttribute;
      return { [profileAttribute]: updatedProfileAttribute };
    });
    const requestObject = Object.assign({}, ...profileDataAsList);

    return instance.put('/userProfile', requestObject);
  },

  patchUserProfile: (
    updateRequest: UserProfilePatchRequest
  ): Promise<AxiosResponse<UserProfile>> =>
    instance.patch('/userProfile', updateRequest),

  getCustomToken: (
    gemaAccessToken: string,
    issuer: IssuerType = 'gemaCiam',
    ciamNonce?: string
  ): Promise<AxiosResponse<string>> => {
    return instance.get(`/exchange?issuer=${issuer}`, {
      baseURL:
        process.env.REACT_APP_GEMA_TOKEN_EXCHANGE_BASE_URL ??
        instance.defaults.baseURL,
      headers: {
        'X-SSO-Token': gemaAccessToken,
      },
      params: {
        nonce: ciamNonce,
      },
    });
  },
  getUserFeatures: (): Promise<AxiosResponse<UserFeatureResponse[]>> => {
    return instance.get('/users/features');
  },

  getExperiments: (): Promise<AxiosResponse<ExperimentResponse>> => {
    return instance.get('/experiments');
  },

  createProfileImageSignedUrl: (): Promise<
    AxiosResponse<SignedUploadUrlResponse>
  > => {
    return instance.post<SignedUploadUrlResponse>(
      `/userProfile/profileImageSignedUrl`
    );
  },
};

/**
 * BE Health Check
 */

export interface HealthResponse {
  status: string;
}

const healthCheck = {
  health: (): Promise<AxiosResponse<HealthResponse>> => {
    return instance.get('/actuator/health');
  },
};

/**
 * GEMA Auth
 */

export const gemaAuthMiddleware = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig<unknown>> => {
  if (config?.url && config.url.includes('/token')) {
    return config;
  }

  const gemaCiamAuthToken = await authentication.getGemaOktaAccessToken();

  logging.info({
    productArea: 'auth',
    message: `Making GEMA service request for gema id ${authentication.user?.issuerId} to ${config?.url}`,
    messageContext: {
      hasGemaCiamAuthToken: Boolean(gemaCiamAuthToken),
    },
  });

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${gemaCiamAuthToken}`,
    },
  };
};

const musicAnalytics = {
  getToken: (): Promise<AxiosResponse<MusicAnalyticsToken>> => {
    return instance.post('/musicanalytics/token');
  },
};

/**
 * GEMA Soundfile Upload
 */

const gemaSoundfileApi = axios.create({
  baseURL: process.env.REACT_APP_GEMA_SOUNDFILE_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

gemaSoundfileApi.interceptors.request.use(gemaAuthMiddleware, (error) => {
  Sentry.captureException(error);
  logging.error({ productArea: 'gema', message: error, error });
  return Promise.reject(error);
});

gemaSoundfileApi.interceptors.response.use(
  (response) => response,
  (error) => {
    logging.error({ productArea: 'gema', message: error, error });
    return Promise.reject(error);
  }
);

const soundfile = {
  createSoundfile: (
    soundFileDocJson: SoundfileDocRequest
  ): Promise<AxiosResponse<SoundfileDocResponse>> =>
    gemaSoundfileApi.put('/soundfiles', soundFileDocJson),

  getSoundfile: (
    trackId: string
  ): Promise<AxiosResponse<SoundfileGetResponseBody>> =>
    gemaSoundfileApi.get(`/soundfiles/${trackId}`),

  uploadSoundFileAudio: (
    signedUrl: string,
    fileData: File
  ): Promise<AxiosResponse> =>
    axios.put(signedUrl, fileData, {
      headers: {
        'Content-Type': 'audio/wav',
      },
    }),
};

const participants = {
  getAllParticipantsByReleaseId: (
    releaseId: string
  ): Promise<AxiosResponse<ParticipantListResponse>> =>
    instance.get(`/participants`, {
      params: {
        releaseId,
      },
    }),

  getAll: (): Promise<AxiosResponse<ParticipantListResponse>> =>
    instance.get('/participants'),

  create: (
    participant: ParticipantRequest,
    optionalTrackId?: string
  ): Promise<AxiosResponse<ParticipantResponse>> =>
    instance.post('/participants', participant, {
      params: {
        trackId: optionalTrackId,
      },
    }),

  update: (
    participantId: string,
    participant: ParticipantRequest,
    optionalTrackId?: string
  ): Promise<AxiosResponse<ParticipantResponse>> =>
    instance.put(`/participants/${participantId}`, participant, {
      params: {
        trackId: optionalTrackId,
      },
    }),

  deleteParticipant: (
    participantId: string,
    optionalTrackId?: string
  ): Promise<void> =>
    instance.delete(`/participants/${participantId}`, {
      params: {
        trackId: optionalTrackId,
      },
    }),

  getParticipantRoles: (): Promise<AxiosResponse<ParticipantRoles>> =>
    instance.get('/participantRoles'),

  getParticipantReleases: (
    participantId: string
  ): Promise<AxiosResponse<Array<ReleaseResponse>>> =>
    instance.get(`/participants/${participantId}/releases`),
};

const api = {
  instance,
  royalties,
  file,
  payout,
  account,
  healthCheck,
  musicAnalytics,
  soundfile,
  participants,
};

export default api;
