import { Divider, Menu, Tooltip } from 'antd';
import React from 'react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Typography from 'component-library/src/typography/Typography';
import Space from 'component-library/src/space/Space';
import { ExternalLink, LogoutIcon } from 'assets/img';
import { spacing } from 'component-library/src/theme/theme';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import theme from 'component-library/src/theme/theme';
import { mixpanel } from 'tracking/tracking';
import { toTitleCase } from 'helper/formatter/formatter';
import routesConfig from 'config/routes';
import { Link } from 'react-router-dom';
import { externalLinks, subMenuIcons } from 'components/nav/assets';
import useIsMobileCheck from 'hooks/useIsMobileCheck';
import { useProfile } from 'hooks/useProfile';
import ImageComp from 'components/image/Image';

interface InnerMenuProps {
  currentMenuKey: string;
  isCollapsed: boolean;
  openKeys?: React.Key[];
  onItemClick: MenuClickEventHandler;
  onSignOut: () => void;
  setSiderCollapsed?: (value: boolean) => void;
  setOpenKeys: (value: React.Key[]) => void;
}

const InnerMenu: React.FC<React.PropsWithChildren<InnerMenuProps>> = ({
  currentMenuKey,
  isCollapsed,
  openKeys,
  onItemClick,
  onSignOut,
  setSiderCollapsed,
  setOpenKeys,
}) => {
  const {
    t,
    i18n: {
      languages: [language],
    },
  } = useTranslation();

  const handleOpenChange = (keys: React.Key[]) => {
    if (isCollapsed) {
      setSiderCollapsed?.(false);
    }
    setOpenKeys(keys);
  };

  const isMobile = useIsMobileCheck();
  const { data: userProfile } = useProfile();

  const accountMenuSubItemTitle = (
    <Space
      direction="horizontal"
      size={spacing.space16}
      className="nav-account-item nav-menu-item w-100"
      data-testid="nav-user"
    >
      {userProfile?.profileImageUrl ? (
        <ImageComp
          testId="profile-image"
          className="profile-image"
          imageAlt="Profile image"
          imageSrc={userProfile?.profileImageUrl}
          fallbackImage={[
            userProfile?.profileImageUrl,
            <UserOutlined key="nav-user-icon" className="nav-user-icon" />,
          ]}
        />
      ) : (
        <UserOutlined className="nav-user-icon" />
      )}
      {isCollapsed ? (
        <></>
      ) : (
        <Typography
          type={isMobile ? 'headline' : 'body'}
          size={isMobile ? 'xs' : 's'}
          weightLevel="500"
          color="secondary6"
        >
          {t(`menu.account`)}
        </Typography>
      )}
    </Space>
  );
  const subMenuIcon = !isCollapsed ? (
    <DownOutlined
      rotate={openKeys?.includes('sub1') ? 180 : 0}
      style={{ color: theme.colors.secondary6 }}
    />
  ) : (
    <span />
  );

  return (
    <Menu
      className="menu"
      mode="inline"
      selectedKeys={[currentMenuKey]}
      openKeys={openKeys as string[]}
      onOpenChange={handleOpenChange}
      inlineIndent={0}
      expandIcon={subMenuIcon}
    >
      <Menu.SubMenu
        title={accountMenuSubItemTitle}
        key="sub1"
        className="mb-8 account-sub-menu"
        data-testid="menu-submenu"
      >
        {routesConfig
          .filter(
            (item) =>
              item.pageKey === 'subscription' ||
              item.pageKey === 'royaltyPayouts' ||
              item.pageKey === 'profileDetails'
          )
          .map((route) => {
            const MenuItem = route.customNavItem || Menu.Item;
            return (
              <MenuItem
                className={`flex ant-menu-item ant-menu-item-only-child ${
                  currentMenuKey === route.pageKey
                    ? 'ant-menu-item-selected'
                    : ''
                }`}
                key={route.pageKey}
                defaultValue={[currentMenuKey]}
                onClick={onItemClick}
              >
                <Link
                  to={route.value}
                  data-testid={`nav-${route.pageKey}`}
                  className="flex-align-c"
                >
                  {subMenuIcons[route.pageKey]}
                  <Typography
                    type={isMobile ? 'headline' : 'body'}
                    size={isMobile ? 'xs' : 's'}
                    weightLevel="500"
                    color="secondary6"
                  >
                    {t(`menu.${route.pageKey}`)}
                  </Typography>
                </Link>
              </MenuItem>
            );
          })}
      </Menu.SubMenu>

      {routesConfig
        .filter((item) => item.isInGlobalNav)
        .map(({ pageKey, customNavItem, isNewFeature }) => {
          const MenuItem = customNavItem || Menu.Item;
          const isTrackBoard = pageKey === 'tracks';
          const pageName = isTrackBoard
            ? t('menu.track-board')
            : t(`menu.${pageKey}`);

          return (
            <MenuItem
              className={classNames({
                'nav-menu-item': true,
                [`nav-menu-item-${pageKey}`]: true,
                'w-100': true,
              })}
              onClick={onItemClick}
              data-testid={`nav-${pageKey}`}
              key={pageKey}
              title={null}
            >
              <Tooltip
                placement="right"
                title={isCollapsed ? pageName : undefined}
              >
                <Typography
                  type={isMobile ? 'headline' : 'body'}
                  size={isMobile ? 'xs' : 's'}
                  weightLevel="500"
                  color="secondary6"
                >
                  <Space
                    className="nav-item-new-feature"
                    direction="horizontal"
                    size={isMobile ? spacing.space4 : spacing.space12}
                  >
                    {subMenuIcons[pageKey]}
                    {isCollapsed ? <></> : pageName}
                  </Space>
                  <Space
                    className={`nav-item-new-feature ml-2`}
                    direction="horizontal"
                  >
                    {!isCollapsed && isNewFeature ? (
                      <div className="new-feature-icon">
                        {t('menu.newFeature')}
                      </div>
                    ) : (
                      <></>
                    )}
                  </Space>
                </Typography>
              </Tooltip>
            </MenuItem>
          );
        })}

      <Divider className="nav-divider" />

      {Boolean(externalLinks.length) &&
        externalLinks?.map((linkRoute) => {
          const href =
            typeof linkRoute.hrefVal === 'string'
              ? linkRoute.hrefVal
              : linkRoute.hrefVal[language];
          return (
            <Menu.Item
              className={classNames({
                'nav-menu-item': true,
                [`nav-menu-item-${linkRoute.linkKey}`]: true,
                'w-100': true,
              })}
              key={linkRoute.linkKey}
            >
              <Tooltip
                placement="right"
                title={
                  isCollapsed ? toTitleCase(linkRoute.navMenuName) : undefined
                }
              >
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                  title={t(`menu.${linkRoute.linkKey}`)}
                  data-testid={`nav-${linkRoute.linkKey}`}
                  onClick={() =>
                    mixpanel.track({
                      event: 'USER_CLICK',
                      url: href,
                      element: linkRoute.linkKey,
                    })
                  }
                >
                  <Typography
                    type={isMobile ? 'headline' : 'body'}
                    size={isMobile ? 'xs' : 's'}
                    weightLevel="500"
                    color="secondary6"
                    customClass="w-100"
                  >
                    <Space
                      className="nav-menu-external-container"
                      direction="horizontal"
                      size={isMobile ? spacing.space2 : spacing.space12}
                    >
                      {subMenuIcons[linkRoute.linkKey]}
                      {isCollapsed ? <></> : t(`menu.${linkRoute.linkKey}`)}
                    </Space>
                  </Typography>

                  {isCollapsed ? (
                    <></>
                  ) : (
                    <ExternalLink
                      className="external-link-icon"
                      color={theme.colors.secondary4}
                    />
                  )}
                </a>
              </Tooltip>
            </Menu.Item>
          );
        })}

      <Divider className="nav-divider" />

      <Menu.Item
        className="nav-menu-item"
        onClick={onSignOut}
        data-testid="nav-signout"
      >
        <Tooltip
          placement="right"
          title={isCollapsed ? t('menu.signOut') : undefined}
        >
          <Typography
            type={isMobile ? 'headline' : 'body'}
            size={isMobile ? 'xs' : 's'}
            weightLevel="500"
            color="secondary6"
          >
            <Space direction="horizontal" size={spacing.space12} align="center">
              <LogoutIcon className="nav-menu-item-icon" />
              {isCollapsed ? <></> : t('menu.signOut')}
            </Space>
          </Typography>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );
};

export default InnerMenu;
