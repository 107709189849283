import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { logging } from 'logging/logging';
import { ReleaseLockFailureResponse } from 'features/release/models/Release';
import { useBeforeUnload, useLocation } from 'react-router-dom';
import releaseService from 'features/release/services/releaseService';

// Create the context
const ReleaseLockContext = createContext<{
  setActiveReleaseId: React.Dispatch<React.SetStateAction<string | undefined>>;
  lockFailure: ReleaseLockFailureResponse | undefined;
  unlockRelease: () => Promise<void>;
} | null>(null);

// Provider component
export const SharingReleaseLockProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeReleaseId, setActiveReleaseId] = useState<string | undefined>();
  const [lockFailure, setLockFailure] = useState<
    ReleaseLockFailureResponse | undefined
  >();
  const isUnlockingRef = useRef(false); // Tracks if unlock is in progress
  const location = useLocation();

  // Lock the release when the activeReleaseId changes
  useEffect(() => {
    const checkReleaseLock = async () => {
      try {
        if (!activeReleaseId) {
          return;
        }
        const lockFailure = await releaseService.lockReleaseForEditing(
          activeReleaseId as string
        );
        if (lockFailure) {
          setLockFailure(lockFailure);
        }
      } catch (e) {
        logging.error({
          message: 'Error locking release:',
          error: e as Error,
        });
      }
    };

    checkReleaseLock();
  }, [activeReleaseId]);

  const unlockRelease = useCallback(async () => {
    if (isUnlockingRef.current || lockFailure?.userId || !activeReleaseId) {
      return; // Skip if already unlocking or someone else has the lock
    }

    try {
      isUnlockingRef.current = true;
      await releaseService.unlockReleaseForEditing(activeReleaseId);
    } catch (e) {
      logging.error({
        message: 'Error unlocking release:',
        error: e as Error,
      });
    } finally {
      // Reset active release id
      setActiveReleaseId(undefined);
      isUnlockingRef.current = false;
    }
  }, [activeReleaseId, lockFailure]);

  // Unlock on navigate if active release ID
  useEffect(() => {
    if (activeReleaseId && !location.pathname.includes(activeReleaseId)) {
      unlockRelease();
    }
  }, [location.pathname, unlockRelease, activeReleaseId]);

  // Unlock the release when the window/tab is closed
  useBeforeUnload(() => {
    unlockRelease();
  });

  return (
    <ReleaseLockContext.Provider
      value={{ setActiveReleaseId, lockFailure, unlockRelease }}
    >
      {children}
    </ReleaseLockContext.Provider>
  );
};

export const useSharingReleaseLockContext = () => {
  const context = useContext(ReleaseLockContext);
  if (!context) {
    throw new Error(
      'useSharingReleaseLockContext must be used within a SharingReleaseLockProvider'
    );
  }
  return context;
};
