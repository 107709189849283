import { Axios } from 'axios';
import { ReleaseCount } from 'features/release/models/Release';
import { instance } from 'helper/api/api';

export class ReleaseCountService {
  releaseCountServiceInstance: Axios;

  constructor(releaseCountServiceInstance: Axios) {
    this.releaseCountServiceInstance = releaseCountServiceInstance;
  }

  apiEndpoint = '/releaseCount';

  async getReleaseCount(): Promise<ReleaseCount> {
    const { data } = await this.releaseCountServiceInstance.get<ReleaseCount>(
      this.apiEndpoint
    );
    return data;
  }
}

export default new ReleaseCountService(instance);
