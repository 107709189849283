import { ReleaseFile, Title } from 'features/release/models/Release';
import {
  TrackParticipants,
  Track,
  TrackParticipant,
} from 'features/track/models/Track';
import { newTrack } from 'factories/ReleaseFactory';

// Steps
export const steps = [
  {
    key: 'release',
    step: 0,
    form: 'releaseForm',
  },
  {
    key: 'artwork',
    step: 1,
    form: 'artworkForm',
  },
  {
    key: 'tracklist',
    step: 2,
    form: 'tracklistForm',
  },
  {
    key: 'distribution',
    step: 3,
    form: 'distributionForm',
  },
  {
    key: 'review',
    step: 4,
    form: 'reviewForm',
  },
];

export const parseFileNameForTitle = (fileName: string): string =>
  fileName.includes('.wav') ? fileName.split('.wav')[0] : fileName;

// Tracks
export const normalizeTrackAfterUpload = (file: ReleaseFile): Track => {
  const title: Title = {};
  title.title = parseFileNameForTitle(file.name);

  const track: Track = newTrack();
  track.title = title;
  track.audio = file;
  return track;
};

export const flattenParticipants = (track: Track): TrackParticipants => {
  const { participants, authors, artists } = track;

  return [...(artists || []), ...(participants || []), ...(authors || [])];
};

// tracklist form input
export const addPositionsToParticipants = (
  trackInput: Track
): TrackParticipants => {
  return flattenParticipants(trackInput)
    .filter((participant: TrackParticipant) => !!participant.name)
    .map((participant: TrackParticipant, index: number) => ({
      ...participant,
      position: index + 1,
    }));
};
