import {
  Title,
  Copyright,
  Distribution,
  Release,
  ReleaseDistributionRequest,
  ReleaseCopyrightRequest,
  ReleaseRequest,
  ReleaseFile,
  ReleeaseArtworkRequest,
  ReleaseTitleResponse,
} from 'features/release/models/Release';
import dayjs, { Dayjs } from 'dayjs';
import { getVersion } from '../../../../helper/form/formHelper';

// This function is duplicated in TrackRequestData, find a way to abstract it
export const toTitleRequestData = (
  title?: Title
): ReleaseTitleResponse | undefined => {
  if (!title) return;
  const REMASTERED = getVersion('REMASTERED').value;
  const { version, language, remasteredYear } = title;

  if (!title.title && !version && !language) return;

  const remasteredYearNormalized = version?.includes(REMASTERED)
    ? remasteredYear?.year()
    : undefined;

  const requestData = {
    title: title.title,
    language,
    ...(version && { version }),
    ...(remasteredYearNormalized && {
      remasteredYear: remasteredYearNormalized,
    }),
  };

  return requestData;
};

export const toDistributionRequestData = (
  distribution?: Distribution
): ReleaseDistributionRequest | undefined => {
  if (!distribution) return;

  const { dsps } = distribution;

  const requestData = {
    // Removing undefineds from DSP array
    dsps: dsps?.filter((n) => n) as ReleaseDistributionRequest['dsps'],
  };

  return requestData;
};

export const toCopyrightRequestData = (
  copyright?: Copyright,
  releaseDate?: Dayjs | null,
  originalReleaseDate?: Dayjs | null
): ReleaseCopyrightRequest | undefined => {
  if (!copyright) return;

  let copyrightYear = dayjs();
  if (originalReleaseDate) {
    copyrightYear = originalReleaseDate;
  } else if (releaseDate) {
    copyrightYear = releaseDate;
  }

  const requestData = {
    name: copyright.name || '',
    year: copyrightYear.year(),
  };

  return requestData;
};

export const toArtworkImageRequestData = (
  file: ReleaseFile
): ReleeaseArtworkRequest => {
  const fileName = file && file.fileName;
  const name = file && file.name;
  const size = file && file.size;

  const requestData = {
    storageFileName: fileName,
    originalFileName: name,
    fileSizeInBytes: size,
  };

  return requestData;
};

export const toReleaseRequestData = (release: Release): ReleaseRequest => {
  const {
    title,
    originalReleaseDate,
    genre1,
    genre2,
    territories,
    copyright,
    recordingRight,
    distribution,
    releaseDate,
  } = release;

  const titleData = toTitleRequestData(title);
  const genre1Data = genre1;
  const genre2Data = genre2;
  const originalReleaseDateData =
    originalReleaseDate && originalReleaseDate.format('YYYY-MM-DD');

  const requestData = {
    title: titleData,
    genre1: genre1Data,
    ...(genre2Data && { genre2: genre2Data }),
    ...(originalReleaseDateData && {
      originalReleaseDate: originalReleaseDateData,
    }),
    territories: territories,
    copyright: toCopyrightRequestData(
      copyright,
      releaseDate,
      originalReleaseDate
    ),
    recordingRight: toCopyrightRequestData(
      recordingRight,
      releaseDate,
      originalReleaseDate
    ),
    distribution: toDistributionRequestData(distribution),
    releasedate: releaseDate
      ? dayjs(releaseDate).format('YYYY-MM-DD')
      : undefined,
  };

  return requestData;
};
