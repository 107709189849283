import React from 'react';
import AntdCheckbox, {
  CheckboxProps as AntdCheckboxProps,
} from 'antd/lib/checkbox';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';
import classnames from 'classnames';
import Typography from 'component-library/src/typography/Typography';

export interface CheckboxProps extends AntdCheckboxProps {
  testId?: string;
  label?: React.ReactNode;
  description?: string;
  stopPropagation?: boolean;
  size?: 's';
  alignTop?: boolean;
  // ensure testid is set through prop not data attr
  ['data-testid']?: undefined;
}

const customClassName = 'custom-checkbox';

const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  label,
  testId,
  alignTop = false,
  ...props
}) => {
  const customClass = classnames(customClassName, {
    [`${customClassName}--description`]: Boolean(props.description),
  });
  const stopPropagation = props.stopPropagation || false;

  return (
    <AntdCheckbox
      {...props}
      className={classnames(customClass, { ['align-top']: alignTop })}
      data-testid={testId}
      onClick={(e) => {
        props.onClick?.(e);
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
      onChange={(e) => {
        props.onChange?.(e);
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      {label ? (
        <Space
          size={{ xs: spacing.space4 }}
          direction="vertical"
          className="w-100"
        >
          <div
            data-testid={`${testId}-label`}
            className={`${customClassName}__label`}
            onClick={(e) => stopPropagation && e.stopPropagation()}
          >
            <Typography
              size={props.size === 's' ? 'xs' : 's'}
              type="body"
              color="neutral9"
              weightLevel={props.size === 's' ? '400' : '500'}
              customClass="flex"
              block={props.size !== 's'}
            >
              {label}
            </Typography>
          </div>
          {props.description ? (
            <div
              data-testid={`${testId}-label`}
              className={`${customClassName}__description`}
            >
              <Typography size="xs" type="body" color="neutral7" block>
                {props.description}
              </Typography>
            </div>
          ) : null}
        </Space>
      ) : (
        undefined
      )}
    </AntdCheckbox>
  );
};

export const CheckboxGroup = AntdCheckbox.Group;

export default Checkbox;
